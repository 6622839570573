/* Login Css Start Here */
h2 {
	font-family: "Poppins";
}
.login {
	background-image: url(../img/login-back.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 100px 0 250px;
}
.verification {
	background-image: url(../img/veri.png);
	background-position: bottom;
}
.login .login_box {
	background: #fff;
	padding: 40px 40px 35px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px #ddd;
}
.login .login_box .heading {
	text-align: center;
	padding-bottom: 25px;
}
.login .login_box .heading h2 {
	font-size: 32px;
	color: #1e1e1e;
	font-family: "Poppins";
	font-weight: 700;
	text-transform: uppercase;
}
.login .login_box .registertxt h2 {
	/* font-family: "monument-regular"; */
	font-family: "Poppins";
}
.change-pass .heading h2 {
	text-transform: capitalize !important;
	font-weight: 700 !important;
}
.login .login_box .heading p {
	color: #808080;
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 300;
}
.login .login_box .heading p a {
	color: #000000;
	font-weight: 700;
}
.login .login_box .form-group .form-control {
	background: #f1f1f1;
	border-radius: 0;
	border: 0;
	padding: 14px 40px;
	font-family: "poppins";
	width: 100% !important;
}
.login .login_box .rememberDv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.login .login_box .rememberDv .form-control {
	width: unset;
}
.login .login_box .rememberDv .form-group {
	display: flex;
	align-items: center;
	margin: 0 0 0 20px;
	position: relative;
}
.login .login_box .rememberDv .form-group label {
	margin: 0;
	font-family: "Montserrat";
	color: #808080;
	margin-left: 1rem;
}
.login .login_box .rememberDv .form-check-input[type="checkbox"] {
	border: 2px solid #acacac;
	margin-top: 0.3px;
	box-shadow: none;
}
.login .login_box .rememberDv a {
	color: #808080;
	font-family: "Montserrat";
}
.login .login_box .button-group {
	margin-top: 30px;
}
.login .login_box button {
	width: 100%;
	background: #44f92c;
	border: 0;
	padding: 17px 0;
	border-radius: 5px;
	font-family: "Montserrat";
	font-weight: 600;
	border-radius: 7px;
}
.login .login_box form {
	text-align: center;
}
.login .login_box form label.title {
	font-size: 20px;
	font-family: "Poppins";
	color: #000;
	font-weight: 500;
	padding: 0 0 20px;
}
/* .login .login_box form > div {
	justify-content: center;
} */
.login .login_box form > div > input {
	background: #ede9e9;
	border: 0;
	width: 60px !important;
	height: 60px !important;
	border-radius: 0;
	box-shadow: none;
	outline: none;
}
.login .login_box .login_socials_dv ul {
	display: flex;
	list-style: none;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 0;
	margin-bottom: 0;
}
.login_socials_dv {
	margin: 1rem 0;
}
.login .login_box form.verifyied label.title ~ div {
	justify-content: center;
}
.login .login_box .login_socials_dv ul i.fa.fa-google {
	color: #db4437;
}
.login .login_box .login_socials_dv ul li a i.fa.fa-apple {
	color: #555555;
}
.login .login_box .login_socials_dv ul li a i {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	border: 1px solid;
}
.login .login_box .login_socials_dv ul li a {
	text-decoration: none;
}
.register .heading h2 {
	text-transform: capitalize !important;
	padding-bottom: 15px;
}
.register .heading h2 {
	text-transform: capitalize !important;
	padding-bottom: 15px;
}
.register .checkk {
	margin-left: 22px;
}
.checkk input {
	margin-left: 1px;
}
.checkk22 {
	position: relative;
}
.checkk22 input {
	position: absolute;
	top: 0px;
	left: 6px;
}
.register .checkk label {
	text-align: left;
	font-size: 14px;
	color: #a5a5a5;
	font-family: "Montserrat";
	margin-left: 10px;
}
.checkk22 label {
	text-align: left;
	font-size: 14px;
	color: #a5a5a5;
	font-family: "Montserrat";
	margin-left: 10px;
}
.register {
	background-image: url("../img/register.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.register .login_box input.form-control::placeholder {
	font-family: "Montserrat";
	font-size: 14px;
}
.register .login_box button {
	color: #000000b5;
	border-radius: 5px;
}
.form-check-input:checked {
	background-color: #44f92c;
	border-color: #44f92c;
}
/* Login Css End Here */
.apple-login-btn div {
	background: #000;
	border-radius: 9px;
	width: 100%;
}
.apple-login-btn div img {
	min-width: 50%;
}
button.google_button_custom {
	background-color: #fff;
	border-radius: 9px;
}
button.google_button_custom * {
	color: #000 !important;
	font-size: 17px;
}
/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
	.login .login_box {
		padding: 40px 10px 35px;
	}
}
@media (max-width: 991px) {
}
@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
	.login .login_box .heading h2 {
		font-size: 27px;
	}
	.login .login_box .heading p {
		font-size: 12px;
	}
	.login .login_box .rememberDv .form-group label {
		font-size: 10px;
	}
	.login .login_box .rememberDv a {
		font-size: 10px;
	}
}
/* ======================== */
