.privacy-banner {
	/* background-image: url("../img/privacy-bg.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: right center;
}
.privacy-banner h2 {
	font-family: "Monument-regular";
	font-size: 41px;
	color: #2a2a2a;
}
.privacy-content-wrapper {
	padding: 100px 0px;
}
.privacy-content-wrapper .content-wrapper h4 {
    font-family: 'montserrat';
    font-size: 20px;
    color: #262626;
    font-weight: 700;
    margin: 30px 0px;
    /* letter-spacing: 1px; */
	font-weight: bold;
}
.privacy-content-wrapper .content-wrapper p {
	font-family: "Montserrat";
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0px;
	color: #262626;
}
.privacy-content-wrapper .content-wrapper {
	margin: 20px 0px;
}
.privacy-content-wrapper li {
	font-family: "Montserrat";
	color: #262626;
}
