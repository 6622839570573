.about-banner {
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background: url("../img/about-us.webp"); */
	background-size: cover;
	background-repeat: no-repeat;
}
.about-banner .about-banner-heading h1 {
	color: #fff;
	font-family: "monument-regular";
}
.about-content-sec {
	padding: 80px 0px;
}
.about-content-sec p {
	font-family: "Poppins";
	font-size: 21px;
	font-weight: 500;
	color: #040d14;
	text-align: center;
}

.content-wrapper2s p {
	text-align: justify;
}

.vision-sec {
	padding: 65px 0px;
	/* background:linear-gradient(45deg, #00000087, transparent), url("../img/vision-bg.webp"); */
	/* background: url("../img/vision-bg.webp"); */
	background-size: cover;
	background-repeat: no-repeat;
	backdrop-filter: blur(15px);
}
.vision-sec .vision-card-wrapper {
	padding: 39px;
	background: #ffffff4d;
	-webkit-backdrop-filter: blur(53px);
	backdrop-filter: blur(15px);
}
.vision-sec .vision-card-wrapper h2 {
	font-family: "monument-regular";
	font-size: 42px;
	color: #fff;
	padding-bottom: 5px;
	letter-spacing: 4px;
}
.vision-sec .vision-card-wrapper p {
	font-size: 15px;
	color: #fff;
	font-family: "poppins";
	word-spacing: 2px;
	letter-spacing: 0px;
}
.mission {
	padding: 100px 0px;
	padding-top: 100px;
	position: relative;
}
.mission img.dots {
	position: absolute;
	right: 17%;
	top: -53px;
	z-index: -2;
}
.mission figure {
	position: relative;
}
.mission img.fluid {
	position: relative;
	z-index: 2;
}
.mission .mission-line {
	position: absolute;
	top: 39%;
	left: -1%;
}
section.mission.ourvision_sec .mission-line {
	position: absolute;
	top: 39%;
	right: 0;
	left: unset;
	transform: rotate(180deg);
}
.mission button.btn {
	background: #44f92c;
	padding: 12px 45px;
	font-family: "Montserrat";
	font-size: 15px;
	font-weight: 700;
	color: #02090f;
}
.mission h2 {
	color: #040d14;
	font-family: "monument-regular";
	font-size: 45px;
	padding-bottom: 5px;
}
.mission p {
	font-family: "Poppins";
	color: #040d14;
	font-size: 15px;
	line-height: 24px;
}
.mission .content-wrapper {
	padding-left: 50px;
}
/* contact starts here */
.contact-us {
	background-image: url("../img/contact-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
}
.contact-form-wrapper h2 {
	text-align: center;
	color: #fff;
	font-family: "monument-regular";
}
.contact-form-wrapper .contact-heading-wrapper {
	padding-bottom: 30px;
}
.contact-form-wrapper h2 {
	text-align: center;
	color: #fff;
	font-family: "monument-regular";
	font-size: 44px;
}
.contact-form-wrapper .contact-heading-wrapper {
	padding-bottom: 30px;
}
.contact-us .form-wrapper .form-control {
	background: #202123;
	border: 1px solid #202123;
	font-family: "Poppins";
	color: #fff;
	box-shadow: none;
	padding: 10px 20px;
	height: 50px !important;
	line-height: 18px;
}
.contact-us textarea {
	min-height: 115px !important;
	resize: none;
}
.contact-us .form-wrapper .btn {
	background: #43f82b !important;
	border: 1px solid #43f82b !important;
	font-family: "Poppins";
	font-weight: 600 !important;
	color: #000002 !important;
}
.contact-us .form-wrapper .form-control::placeholder {
	color: #fff;
	font-size: 11px;
}
.contact-us .form-wrapper select {
	font-size: 11px;
}
.contact-us .rc-anchor-light {
	background: #202123 !important;
	color: #000 !important;
}
.contact-us .rc-anchor-light.rc-anchor-normal {
	border: 1px solid #202123 !important;
}
.contact-us .rc-anchor-checkbox-label {
	color: #fff !important;
}
/* contact ends here */

@media (max-width: 1440px) {
	.vision-sec {
		padding: 18px 0px;
		background-size: cover;
	}
}
@media (max-width: 1366px) {
	.mission-line img {
		width: 70%;
	}
	.vision-sec .vision-card-wrapper h2 {
		font-size: 30px;
	}
	.vision-sec .vision-card-wrapper {
		padding: 25px;
	}
	.vision-sec .vision-card-wrapper p {
		font-size: 14px;
	}
	.vision-sec {
		padding: 46px 0px;
		background-size: cover;
	}
	.mission-line img {
		width: 55%;
	}
}
@media (max-width: 1280px) {
	.vision-sec .vision-card-wrapper {
		padding: 41px;
	}
	.vision-sec .vision-card-wrapper p {
		font-size: 13px;
	}
	.vision-sec {
		padding: 25px 0px;
	}
}
@media (max-width: 1200px) {
	.vision-sec .vision-card-wrapper p {
		font-size: 12px;
	}
	.vision-sec .vision-card-wrapper h2 {
		font-size: 25px;
	}
	.vision-sec .vision-card-wrapper {
		padding: 30px;
	}
	.vision-sec {
		padding: 43px 0px;
	}
}
@media (max-width: 1100px) {
	.vision-sec .vision-card-wrapper p {
		font-size: 11px;
	}
	.vision-sec .vision-card-wrapper {
		padding: 25px;
	}
	.vision-sec {
		padding: 31px 0px;
	}
}

@media (max-width: 1024px) {
}
@media (max-width: 991px) {
}
@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
	.contact .form-group {
		width: 100%;
	}
	.mission {
		padding: 0px 0px;
	}
	.mission .content-wrapper {
		padding-left: 0px;
	}
}