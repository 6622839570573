.carpreview {
  background: #dcfed7;
  padding-bottom: 60px !important;
}
/* .carpreview {
  background: #dcfed7;
  height: 100vh;
} */
.car-nav-links ul {
  padding: 0px;
  list-style: none;
  display: flex;
}
.car-nav-links ul li {
  margin: 0px 30px;
}
.car-nav-links {
  margin: 30px 0px;
}
.car-nav-links ul li a {
  color: #000000;
  font-family: "Monument-regular";
  font-size: 12px;
}
.carpreview .img-list-wrapper img {
  margin: 0px 10px;
}
