.firstSection {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 40px;
}
.firstSection .headingH4 {
	color: #1d1d1d;
	font-family: "Monument Extended", sans-serif;
}
.search input {
	border: none;
	box-shadow: 1px 1px 5px #ccc;
	border-radius: 50px;
	padding: 14px 20px;
	font-family: "Montserrat";
	font-size: 14px;
}
.search input:focus {
	outline: none;
}
/* .firstSection h2.headingH4 {
	flex: 2 1;
	font-size: 45px;
} */

.firstSection h2.headingH4 {
	font-size: 45px;
}
.pageCount button {
	font-family: "Poppins";
	font-size: 13px;
}
.firstSection .search {
	flex: 1;
	display: flex;
	position: relative;
}
.firstSection .search input {
	width: 50%;
	position: absolute;
	right: 7px;
	top: 4px;
	padding: 15px 40px;
}
.firstSection .search button.searchIcon {
	border: 0;
	/* background: #72ff13; */
	width: 45px;
	height: 45px;
	border-radius: 50%;
	line-height: 30px;
	position: absolute;
	right: 8px;
	top: 8px;
}
.firstSection .search button.searchIcon img {
	width: 35px;
}

.card_Item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 70px;
	margin: 60px 0;
}
.card.blog_carousel {
	margin-left: 10px;
	margin-right: 10px;
}
section.pt-5.blog_sec .slick-prev {
	left: -25px !important;
}
section.pt-5.blog_sec .slick-next {
	right: -25px !important;
}
.blog_banner_title h1 {
	font-size: 70px;
	text-align: center;
	color: #fff;
	padding-bottom: 15px;
	color: #ffffff;
	font-family: "monument-bold";
	line-height: 40px;
	font-size: 60px;
	letter-spacing: 4px;
}
.card {
	border: none;
	box-shadow: 1px 1px 5px #e7eaf3;
	margin-bottom: 50px;
}
.card .card-body {
	padding-bottom: 50px;
}
.card .card-body h5.card-title {
	font-family: "poppins";
	font-weight: 600;
	margin: 0;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 1;
}
.card .card-body p.card-text {
	font-family: "Poppins";
	font-size: 14px;
	line-height: 24px;
	margin: 0;
}
.card .card-body a.btn.btn-primary {
	font-family: "Poppins";
	font-weight: 600;
	padding: 11px 35px;
	margin-top: 15px;
}
.pagination {
	margin-bottom: 20px;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	margin-top: 20px;
}

.pageCount .btn1 {
	border: none;
	background: #1f1f1f;
	color: #f5f5f5;
	padding: 10px 20px;
	display: inline-block;
	margin: 0 4px;
}

.pageCount .btn3 {
	border: none;
	background: #72ff13;
	color: #f5f5f5;
	padding: 10px 20px;
	display: inline-block;
	margin: 0 4px;
}

.pageCount .btn2 {
	background-color: transparent;
	border: 2px solid #72ff13;
	padding: 8px 20px 8px 20px;
	margin: 0 4px;
}
.pageCount .btn2 i {
	position: relative;
	color: #72ff13;
	font-size: 20px;
	font-weight: 700;
	top: 1.5px;
	left: 6px;
}
.card .img_box {
	position: relative;
}
.card .img_box .date {
	position: absolute;
	top: 10px;
	left: 15px;
}
.card .img_box .date p {
	color: #000;
	font-family: "Poppins";
	font-size: 13px;
	background: #baffb1;
	padding: 2px 12px;
}
