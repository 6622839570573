@import url("https://fonts.cdnfonts.com/css/monument-extended");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

body {
	overflow-x: hidden;
}

@font-face {
	font-family: "monument-bold";
	src: url(../Fonts/MonumentExtended-Ultrabold.otf);
}

@font-face {
	font-family: "monument-regular";
	src: url(../Fonts/MonumentExtended-Regular.otf);
}

@font-face {
	font-family: "GT Walsheim";
	src: url(../Fonts/GT\ Walsheim\ Pro\ Regular\ Regular.ttf);
}
.SetErrorshow {
	border-radius: 8px !important;
	border: 2px solid rgb(225 29 72) !important;
}

/* #Toast {
	z-index: 9999999 !important;
} */

.errorMessage p {
	display: flex;
	margin: -5px 0px 10px 10px;
	font-size: 14px !important;
}

.SetNotError {
	border: 2px solid rgb(75 85 99);
	background-color: rgb(249 250 251);
}

.card_head h3 {
	display: -webkit-box !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	-webkit-line-clamp: 1 !important;
	height: unset !important;
	max-height: 17px !important;
}

/* Begin of Slider  */

/* Actions Button Start Here */
.actionLink {
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 2;
}

.actionLink .contactDv {
	display: flex;
	align-items: end;
	transform: translateX(100%);
	transition: 0.6s;
	position: relative;
}
.actionLink .contactDv:hover {
	transform: translateX(0%);
}
/* .actionLink .contactDv:hover {
  transform: translateY(-200px);
  -webkit-transform: translateY(-200px);
  -moz-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  -o-transform: translateY(-200px);
} */

.slider-input22 input::placeholder {
	color: rgba(68, 67, 67, 0.356) !important;
}

.actionLink .contactDv .contact-btn {
	transform: rotate(-90deg);
	background: #72ff13;
	/* height: 75px; */
	width: 203px;
	text-align: center;
	line-height: 60px;
	color: #515151;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 50px;
	border-radius: 15px 15px 0 0px;
	cursor: pointer;
	position: absolute;
	right: 140px;
	bottom: 93px;
	transition: 0.6s;
}

.SetAll_btn > td > .btn2 {
	font-size: 14px;
	font-family: "Montserrat";
	background-color: #72ff13;
	color: #000000;
	padding: 8px 35px;
	/* line-height: 80px; */
	border: 1px solid #72ff13;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	width: 131px;
	padding: 10px 0px;
	margin-top: 5px;
	border-radius: 3px;
}

.SetAll_btn > td > .btn2:hover {
	background: transparent;
	color: #72ff13;
	padding: 12px 20px;
}

/* .actionLink .contactDv:hover .contact-btn {
  width: 155px;
  height: 60px;
  bottom: 0px;
} */

/* .actionLink .contactDv.newsletter .contact-btn {
  width: 130px;
  right: 80%;
  background: #000;
  color: #fff;
  top: 0;
} */
.unversal_heading h1 {
	padding-bottom: 15px;
	color: #ffffff;
	font-family: "monument-bold";
	line-height: 40px;
	font-size: 60px;
	letter-spacing: 4px;
	/* text-shadow: 2px 2px #000000; */
	text-align: center;
}
.actionLink .contactDv .contact-btn {
	bottom: 0;
	background: #000;
	color: #fff;
	width: 150px;
	right: 79%;
}

.actionLink .contactDv.newsletter .contact-btn {
	top: -30px;
}

.actionLink .contactDv.newsletter .contact-btn {
	width: 175px;
	font-size: 16px;
	right: 74%;
	top: 58px;
}

.actionLink .contactDv .chatDv {
	background: #fbfbfb;
	padding: 20px 20px;
	width: 220px;
	border-radius: 5px;
}

/* .contactDv.newsletter .chatDv {
  width: 300px;
} */
.actionLink .contactDv .chatDv h3 {
	color: #0c0c0c;
	font-size: 15px;
	font-family: "Montserrat";
	font-weight: 600;
	margin-bottom: 10px;
}

.actionLink .contactDv .chatDv .form-control {
	border-radius: 0;
	color: #959292;
	font-family: "Montserrat";
	font-size: 10px;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #707070c2;
	padding: 9px 0;
	overflow: unset;
	box-shadow: none;
}

.actionLink .contactDv .chatDv textarea {
	height: 70px;
	resize: none;
}

.actionLink .contactDv .chatDv button {
	background: #72ff13;
	border: 0;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 12px;
	width: 100%;
	padding: 9px 0;
	border-radius: 5px;
}

.contactDv.newsletter .chatDv button {
	width: auto;
	padding: 10px 30px;
	border-radius: 0;
}

.chat-now {
	display: flex;
	align-items: center;
	cursor: pointer;
	right: 5%;
	z-index: 3;
	position: fixed;
	margin-top: 0;
	right: 7%;
	margin-right: -6%;
	bottom: 10%;
}
.chat-now .react-chat-frame {
	right: 2%;
	bottom: 8%;
}

.chat-now label {
	margin-right: 15px;
	margin-bottom: 0;
	font-family: "Montserrat";
	font-weight: 700;
	color: #515151;
}

.chat-now figure {
	margin: 0;
}

/* Actions Button End Here */

.john-doe h4 {
	font-size: 20px;
	color: #333333;
	font-family: "Roboto", sans-serif;
}

/* .Header .row {
    display: flex !important;
    align-items: end !important;
} */

.header-logo img {
	width: 42%;
}

.slides.for1 {
	background-color: #f5f5f5;
}

.slider-input {
	text-align: center;
	position: relative;
	flex: 1;
}

.search-icon img {
	/* background-color: #72FF13; */
	/* padding: 13px 14px; */
	width: 46px !important;
	border-radius: 35px;
	cursor: pointer;
	transition: 0.6s;
}

.search-icon2 img {
	width: 54px !important;
	border-radius: 35px;
	cursor: pointer;
	transition: 0.6s;
}
/* .search-icon:hover i {
	background-color: #17750a;
	color: #fff;
} */
.slider-all .searchBox input::placeholder {
	font-size: 18px;
	font-family: "Montserrat";
	font-weight: 400;
	color: #757575;
}

/* .search-icon {
    position: absolute;
    top: 0px;
    right: 0;
    left: 490px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.slider-all .searchBox {
	display: flex;
	align-items: center;
	width: 80%;
	position: relative;
	justify-content: center;
	margin: 0px auto;
}

.slider-all .searchBox .search-icon {
	position: absolute;
	right: 5px;
	bottom: 2px;
}

.slider-input h4 {
	font-family: "Poppins";
	color: #fff;
	text-transform: uppercase;
	font-size: 24px;
}

.slider-input input {
	position: relative;
	width: 100%;
	padding: 12px 40px;
	border: none;
	border-radius: 60px;
}

.slider img {
	width: 100%;
	position: relative;
}

.slider {
	position: relative;
}

.testi h2 {
	font-size: 27px;
	color: #2a2a2a;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	padding: 40px;
}

.testi {
	text-align: center;
}

.for-flexing {
	display: flex;
	align-items: center;
	/* background-color: white; */
}

/* .HowItWorks .sliderx-img img {
	width: 380px;
	height: 320px;
	position: relative;
	margin-left: -50px;
} */

.HowItWorks .sliderx-img img {
	/* height: 331px; */
	object-fit: cover;
	width: 100%;
	height: 313px;
	/* object-fit: initial; */
	position: relative;
	/* right: 49px; */
	/* margin-left: -50px; */
}

.slides.for1 {
	background-color: white;
}

.slider-para {
	display: flex;
	flex-direction: row;
	background-color: #cbfbc47a;
	padding: 6rem 4rem;
}

.slider-paras2 {
	display: flex;
	flex-direction: row;
	padding: 6rem 4rem;
}

/* .slider-para {
	display: flex;
	flex-direction: row;
	background-color: #72FF1385;
} */
.slider-para p {
	color: #000;
	/* color: #72ff13; */
	font-family: Montserrat, sans-serif;
	font-size: 15px;
	font-weight: 500;
	padding-left: 42px;
	padding-top: 80px;
	padding-right: 80px;
}

.slider-all {
	position: absolute;
	top: 200px;
	right: 0;
	left: 0;
	align-items: center;
	justify-content: center;
}

.slide-text h2 {
	margin: 0;
	font-size: 90px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
}

.slide-text p {
	font-size: 15px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
	margin: 0;
}

.slides.for1 {
	background-color: #f5f5f5;
	padding: 0px 80px;
	padding-bottom: 70px;
}

.slide-text h2 span {
	color: #232323;
}

.slide-btn a {
	background-color: #232323;
	padding: 7px 58px;
	color: #ffffff;
	text-decoration: unset;
	border: 1px solid #aeaeae;
	line-height: 90px;
}

.slide-text h5 {
	font-size: 12px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	margin: 0;
	position: relative;
	left: 60px;
}

.slide-text h5::before {
	content: "";
	background-color: #232323;
	width: 6%;
	height: 3px;
	position: absolute;
	top: 6px;
	left: -59px;
}

section.about-sec {
	background-image: url("../img/pic2.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding: 70px 0px;
	margin-top: -6px;
}

.slidz-img {
	align-items: center;
	background-color: transparent;
	display: flex;
	height: 65px;
	justify-content: center;
	left: 22px;
	top: 7px;
	position: absolute;
	width: 85px !important;
}

.slidz-img2 {
	align-items: center;
	/* color: #43f82b !important; */
	display: flex;
	height: 65px;
	justify-content: center;
	right: 26px;
	bottom: 60px;
	position: absolute;
	width: 85px !important;
}

.slidz-img2 img {
	object-fit: contain;
	height: 74px !important;
	width: 68px !important;
}

.HowItWorks .slidz-img img {
	object-fit: contain;
	height: 74px;
	width: 65px;
}

.john-doe img {
	width: 20% !important;
	margin-left: -14px;
}

.slick-prev:before {
	content: "\f053" !important;
	font-family: "FontAwesome" !important;
	color: #000 !important;
}

.slick-next:before {
	content: "\f054" !important;
	font-family: "FontAwesome" !important;
	color: #373737 !important;
}

.HowItWorks {
	padding-top: 70px;
	background: #f5f5f5;
}

.slick-prev {
	left: 25px !important;
	z-index: 9999999 !important;
}

.slick-next {
	right: 35px !important;
}

.slick-prev:before,
.slick-next:before {
	font-size: 35px !important;
}

.sell-btn a:hover {
	background-color: transparent;
	border: 1px solid #72ff13;
	color: #72ff13;
}

/* End of Slider  */

.header-list ul li ul.dropdown-inner {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	display: block !important;
}

.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}

/* Begin of about section  */
.abt-img img {
	width: 100%;
}

.abt-text h3 {
	font-size: 47px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 400;
}

.abt-all h4 {
	font-size: 20px;
	color: #ffffff;
	font-family: "Nunito", sans-serif;
	font-weight: 400;
	padding: 20px 0px;
}

.abt-all p {
	font-size: 13px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 400;
}

.about-head h2 {
	font-size: 48px;
	color: #ffffff;
	font-family: "Lato", sans-serif;
	font-weight: 700;
}

.about-head {
	text-align: center;
	padding: 40px 0px;
}

.for-flex {
	display: flex;
	align-items: center;
}

/* End of about section  */

/* Begin of service section  */
.service-img img {
	width: 100%;
}

.service-text h3 {
	font-size: 35px;
	color: #a8741a;
	font-family: "Nunito";
	font-weight: 700;
	position: relative;
	left: 70px;
}

.service-text p {
	font-size: 13px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 300;
}

section.service-sec .row {
	display: flex;
	align-items: center;
}

.service-head h2 {
	font-size: 48px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 500;
}

.service-text h3::before {
	content: "";
	width: 12%;
	height: 4px;
	background-color: #ffffff;
	position: absolute;
	left: -69px;
	top: 17px;
}

section.service-sec {
	background-image: url("../img/pic3.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding: 80px 0px;
	margin-top: 0px;
}

.service-head {
	text-align: center;
	padding: 40px;
}

.service-text a {
	font-size: 13px;
	color: #ffffff;
	text-decoration: underline;
}

.for-allign {
	text-align: end;
}

.for-allign h3 {
	position: relative;
	left: 0;
}

.for-allign h3::before {
	content: "";
	width: 12%;
	height: 4px;
	background-color: #ffffff;
	position: absolute;
	left: 249px;
	top: 17px;
}

/* .slick-slider .slick-center{
    background-color: red;
    padding: 50px;
    color: blue;
    margin: 0 1.5rem;
}


.slick-slider .slick-current{background-color: red;
    padding: 50px;
    color: blue;

} */
.contact .form-group {
	width: 75%;
	margin: 0 auto;
}

.HowItWorks img {
	width: 100%;
}

.HowItWorks ~ section.contact .form-group {
	width: 62%;
	margin: 0 auto;
}

/* .how-sec .slides img {
    width: 50%;
    margin: 0 auto;
}

.how-sec .slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.7);
} */
/* End of service section  */

/* Begin of Header section  */
.header-list ul {
	display: flex;
	list-style: none;
	align-items: center;
}

/* .Header {
    position: absolute;
    width: 100%;
    z-index: 999999;
    padding: 20px 0px;
    top: 0;
} */

.header-list ul li {
	font-size: 12px;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	color: #000;
	/* padding: 5px 10px; */
	position: relative;
}

.header-list a.login-btn {
	background-color: #72ff13;
	padding: 12px 20px;
	color: #000000;
	font-family: "Montserrat";
	font-weight: 700;
	transition: 1s;
	border: 1px solid #72ff13;
	text-decoration: unset;
	border-radius: 5px;
}

.header-list {
	float: right;
}

.login-btn a {
	background-color: #232323;
	padding: 10px 47px;
	border: 1px solid #aeaeae;
	color: #ffffff;
}

.login-btn {
	text-align: right;
}

.login-btn img {
	padding-right: 19px;
}

.Header .row {
	display: flex;
	align-items: end;
}

/* End of Header section  */

/* Begin of Car section  */
.car-dv img {
	width: 100%;
}

.for-car1:before {
	content: "01";
	font-size: 112px;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -2px;
	left: 44px;
	color: #72ff133b;
}

.john-doe h5 {
	font-size: 12px;
	/* color: #33333369; */
	color: #000;
	font-family: "Roboto";
}

.for-car2:before {
	content: "02";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -2px;
	left: 52px;
	color: #72ff133b;
}

.for-car3:before {
	content: "03";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -5px;
	left: 55px;
	color: #72ff133b;
}

.for-car4:before {
	content: "04";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 55px;
	color: #72ff133b;
}

.car-dv {
	box-shadow: 0px 0px 11px #70707042;
	height: 170px;
	padding: 17px 45px 17px;
	border-radius: 10px;
	transition: 0.6s;
	overflow: hidden;
}

.car-dv.wow.animate__.animate__flipInY.animated img.img-fluid {
	max-width: 100%;
	height: 100%;
	object-fit: contain;
}

.tesla-model h5 {
	font-size: 17px;
	/* color: #707070; */
	color: #000;
	font-family: "Montserrat";
	font-weight: 700;
}

.tesla-model {
	padding-top: 20px;
}

.car-head h3 {
	font-size: 26px;
	color: #2a2a2a;
	font-family: "montserrat";
	font-weight: bold;
	padding-bottom: 25px;
	letter-spacing: 3px;
}

section.profile-messsage-sec ~ section.feature-sec .car-head h3 {
	font-size: 22px;
	color: #434344;
	font-family: "Poppins";
	font-weight: bold;
	padding-bottom: 25px;
	letter-spacing: 0;
}

section.car-sec {
	padding-top: 50px;
}

/* End of Car section  */

/* Feature section  */

.feature-normal-headds h3 {
	font-size: 13px;
	/* color: #929292; */
	color: #000;
	font-family: "Montserrat";
	font-weight: 600;
	line-height: 1.2;
}

.feature-icon-headds h6 {
	margin: 0;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 3;
	margin-bottom: 15px;
}
.feature-head p {
	font-size: 9px;
	color: #2a2a2a;
	font-weight: 600;
	background-color: #72ff13;
	margin: 0;
	padding: 2px 7px;
	font-family: "Montserrat";
}

.feature-normal-head h3 {
	font-size: 13px;
	/* color: #929292; */
	color: #000;
	font-family: "Montserrat";
	font-weight: 600;
	padding-bottom: 18px;
	line-height: 1.2;
}

.feature-normal-head h6 {
	margin: 0;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 3;
}

.feature-head h2 span {
	font-size: 13px;
	color: #2a2a2a;
	font-family: "Montserrat";
	position: relative;
	bottom: 5px;
}

.feature-head h2 {
	font-size: 22px;
	color: #2a2a2a;
	font-family: "Montserrat";
	font-weight: 700;
}

.feature-head {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 10px 0px;
	padding-bottom: 0;
}

.feature-icon-head span {
	font-size: 10px;
	/* color: #b2b2b2; */
	color: #000;
	font-family: "Montserrat";
	font-weight: 600;
	padding-right: 22px;
}

.feature-iiimmgg img {
	background-color: #fff;
	box-shadow: 0px 0px 11px #70707042;
	border-radius: 40px;
	height: 22px;
	width: 22px;
	object-fit: none;
}

.feature-iiimmgg span {
	font-size: 10px;
	/* color: #b2b2b2; */
	color: #000;
	font-family: "Montserrat";
	font-weight: 600;
}

.feature-icon-head span img {
	background-color: #fff;
	box-shadow: 0px 0px 11px #70707042;
	border-radius: 40px;
	height: 22px;
	width: 22px;
	object-fit: none;
}

.feature-iiimmgg {
	padding: 10px 0px;
}

.btn {
	font-size: 14px;
	font-family: "Montserrat";
	background-color: #72ff13;
	color: #000000;
	padding: 8px 35px;
	/* line-height: 80px; */
	border: 1px solid #72ff13;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
}
.btn22 {
	padding: 8px 12px;
}

.header-list ul li .btn-secondary:not(:disabled):not(.disabled).active,
.header-list ul li .btn-secondary:not(:disabled):not(.disabled):active,
.header-list ul li .show > .btn-secondary.dropdown-toggle {
	background-color: #72ff13;
	border: 1px solid #72ff13;
	color: #000000;
	box-shadow: unset;
}
.header-list ul li .dropdown .btn:hover {
	background: transparent;
	color: #72ff13;
	padding: 12px 20px;
}
.header-list ul li a.total-cart-icon {
	margin-right: 20px;
	position: relative;
}
.header-list ul li .dropdown-menu.show a.dropdown-item {
	font-size: 16px;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
}
.sellbtn {
	font-weight: 600;
	padding: 10px 40px !important;
}

.featured-all img {
	position: relative;
}

.new-listing h5 {
	font-size: 8px;
	color: #2a2a2a;
	background-color: white;
	padding: 6px 14px;
	font-family: "Montserrat";
	font-weight: 600 !important;
	text-transform: uppercase;
}

.new-listing {
	position: absolute;
	top: 6px;
	left: 24px;
}

.f-img img {
	height: 143px;
	width: 100%;
	object-fit: contain;
}

section.feature-sec {
	padding: 60px;
}

.upper-padding {
	padding-top: 70px;
}

.TestimonialBtn {
	font-size: 20px;
	font-family: "monument-regular";
	font-weight: 900;
	color: #444444;
}

.see-all a {
	font-size: 15px;
	color: #000000;
	font-family: "Montserrat";
	background-color: #72ff13;
	padding: 10px 40px;
	font-weight: 600;
}

.see-all {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 20px;
}

.btn:hover {
	background-color: transparent;
	border: 1px solid #72ff13;
	color: #72ff13;
}

/* End of feature seciton  */

/* Begin of Sell section  */
section.sell-sec {
	/* background-image: url(../img/sell2.png); */
	background-repeat: no-repeat;
	width: 100%;
	background-size: 100% 100%;
	padding: 80px 0px;
}

.sell-head h3 {
	font-size: 39px;
	color: #ffffff;
	font-family: monument-regular;
	margin: 0;
}

.sell-head p {
	font-size: 16px;
	color: #ffffff;
	font-weight: 300;
	font-family: "Montserrat";
	padding-bottom: 40px;
}

.sell-head h2 {
	font-size: 83px;
	color: #ffffff;
	font-family: monument-regular;
	margin: 0;
	padding-bottom: 0px;
	padding-top: 9px;
}

.subBtn {
	font-size: 12px;
	color: #fff;
	font-family: "Montserrat";
	/* font-weight: 700; */
	background-color: #000000 !important;
	padding: 7px 50px;
	transition: 1s;
	border-radius: 5px;
	text-decoration: unset;
	cursor: pointer;
}

/* End of Sell section  */

/* Begin of Footer  */
.copy-right {
	background-color: #44f92c;
	text-align: center;
	margin-top: 10px;
}

.copy-right p {
	font-size: 16px;
	color: #373737;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	margin: 0;
	padding: 14px;
}

.footer-head ul li {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: lin;
	line-height: 28px;
}

footer .widget3 {
	margin: 0px auto;
	display: table;
}

.footer-head ul li a {
	color: #1d1d1d;
	font-weight: 500;
	font-size: 14px;
}

.footer-head ul {
	list-style: none;
	padding: 0px 6px;
}

.footer-head {
	padding-top: 28px;
}

.footer-para p {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 500;
}

.footer-social-item ul {
	display: flex;
	list-style: none;
	padding: 0;
}

/* Contact page Start */
.contactSection {
	display: flex;
	flex-direction: row;
	text-align: center !important;
	margin-top: 5px;
}

.contactSection2 {
	display: flex;
	flex-direction: row;
	text-align: center !important;
}

.contactSection3 {
	display: flex;
	flex-direction: row;
	text-align: center !important;
}

.contactSection span:first-child {
	margin-top: -4px;
}

.contactSection2 span:first-child {
	margin-top: -4px;
}

.contactSection3 span:first-child {
	margin-top: -4px;
}

.contactSection p {
	font-size: 14px;
	margin-left: 5px !important;
}

.contactSection2 p {
	font-size: 14px;
	margin-left: 5px !important;
}

.contactSection3 p {
	font-size: 14px;
	margin-left: 5px !important;
}

.iconzz {
	width: 20px !important;
	height: 30px !important;
}

/* .contact {
	background-image: url("../img/contactbg2.webp");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 100px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 50px;
} */

.contact {
	/* background-image: url("../img/Contact_back.png"); */
	background-image: url("../img/contactbg2.webp");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 32px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	/* padding-top: 50px; */
}

.contact button {
	font-family: "poppins";
	font-weight: 600;
	margin-top: 31px;
	padding: 14px 17px;
}

/* .Contact_us_head {
	font-size: 18px;
	color: #ffffff;
	font-family: monument-regular;
	margin-bottom: 50px;
} */

.Contact_us_head {
	font-size: 18px;
	color: #ffffff;
	font-family: monument-regular;
	margin-bottom: 32px;
}

.Contact_us_head h4 {
	letter-spacing: 5px;
	font-size: 40px;
	text-shadow: 2px 2px #000;
}

.first_select::placeholder {
	/* color: rgba(255, 255, 255, 0.781); */
	color: #000;
	font-size: 13px;
}

.first_select {
	/* background-color: #202123; */
	background-color: #fff;
	color: #000;
	border: none;
	margin-bottom: 15px;
	padding: 15px;
	font-family: "GT Walsheim";
	padding-left: 2rem;
	height: calc(2.5rem + 2px);
}

.first_select:focus {
	outline: none !important;
}
.textArea_contact textarea.form-control.first_select {
	height: auto;
}
.select_box11 .first_select {
	/* background-color: #202123; */
	background-color: #fff;
	color: #000;
	border: none;
	margin-bottom: 15px;
	padding: 10px 15px;
	background-image: url(../img/arrow-down1.png);
	background-position-x: 98%;
	background-position-y: center;
	background-repeat: no-repeat;
	height: calc(2.5rem + 2px) !important;
	line-height: 1.5;
	padding-left: 2rem;
	font-size: 14px;
	/* filter: brightness(0);
	 */
	position: relative;
	background-size: 20px;
}
/* .select_box11 .first_select::after {
    content: "\f107";
	font-family: "FontAwesome";
    color: #000;
    position: absolute;
    right: 0;
    background: #000;
} */
/* .Icon_set_contact {
	position: absolute;
	top: 35px;
	right: -5px;
} */

.first_select:focus {
	outline: none;
	/* background-color: #202123; */
	color: #000;
	background-color: #fff;
}

/* .first_contant_contact .form-control::placeholder {
	padding-left: 8px;
} */

.first_contant_contact,
.second_contant_contact {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.rc-anchor-light {
	background: #202123 !important;
	border: 0 !important;
	color: white !important;
}

/* Contact page End */
.footer-head h3 {
	font-size: 19px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 15px;
}

.footer-logo p {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 500;
}

.footer-social-item ul li a {
	background-color: #44f92c;
	padding: 8px 10px;
	color: #ffffff;
	margin-right: 9px;
	width: 44px;
	display: block;
	text-align: center;
	height: 40px;
	border-radius: 5px;
}

.footer-social-item ul li a svg {
	fill: #fff;
	width: 17px;
}

.footer-social-item h3 {
	font-size: 13px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 700;
	padding-bottom: 7px;
	padding-top: 7px;
}

footer {
	padding-top: 30px;
}

footer .widget4 {
	margin: 0px 0 0 auto;
	display: table;
}

.footer-input input {
	padding: 10px 30px;
}

.footer-btn .btn {
	background-color: #44f92c;
	border: unset;
	padding: 12px 30px;
	flex: 0 0 30%;
	font-family: "Montserrat";
	/* font-weight: 700; */
	text-transform: uppercase;
	font-size: 12px;
}

/* .footer-input input {
	position: relative;
	border: 1px solid #5d5d5d;
	margin: 0px;
	width: 70%;
	height: 42px;
	padding: 10px;
	flex: 0 0 70%;
} */

.footer-input {
	position: relative;
	display: flex;
	flex-direction: column;
}

.footer-btn .btn {
	position: absolute;
	top: 0;
	right: 9px;
}

.footer-input input::placeholder {
	color: #aaaaaa;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 15px;
}

/* End of Footer  */

.feature-icon-head span img {
	margin-right: 4px;
}

.feature-iiimmgg span img {
	margin-right: 6px;
}

.header-list .login-btn:hover {
	background-color: transparent;
	border: 1px solid #72ff13;
	color: #72ff13;
}

.header-list ul li a {
	color: #606060;
	text-decoration: none;
	text-transform: capitalize;
	position: relative;
	padding: 5px 10px;
}

.header-list .total-cart-icon svg {
	/* font-size: 13px; */
	width: 20px;
	height: 18px;
	margin-top: 0;
}
.header-list ul li a.total-cart-icon:hover {
	background: transparent;
}
.header-list span.total-cart-no {
	background: #72ff13;
	width: 15px;
	height: 15px;
	color: #fff;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	line-height: 1.5;
	position: absolute;
	right: -2px;
	top: -5px;
	font-size: 10px;
}

.header-list span.total-cart-no:hover {
	background: #72ff13;
}
.header-list ul li a:hover {
	background: #72ff13;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
}
.header-list ul.dropdown-inner li a {
	padding: 0;
	padding: 5px 15px;
	display: block;
}
.header-list ul li a.active {
	background: #72ff13;
	color: #fff;
	padding: 5px 11px;
	border-radius: 4px;
}
/* .header-list ul li a.active:after {
  content: "";
  position: absolute;
  background: #72FF13;
  width: 20px;
  height: 8px;
  bottom: -10px;
  right: 0;
  left: 0;
  border-left: 7px solid #ffffffb0;
  border-right: 7px solid #ffffff9c;
  border-radius: 2px;
  margin: auto;
} */
/* .header-list ul li a.active{
  color: red;
} */
.header-list ul li span {
	display: block;
}

.header-list ul li span img {
	float: right;
	position: relative;
	right: 9px;
}

.feature-add-img img {
	width: 100%;
}

.feature-add-img img {
	height: 350px;
}

.car-dv:hover {
	filter: brightness(0.5);
	cursor: pointer;
}

.featured-all:hover .f-img {
	/* transform: scale(1.1);	 */
	cursor: pointer;
	filter: brightness(0.5);
}

.featured-all .f-img {
	transition: 0.6s;
	position: relative;
	box-shadow: 0px 0px 8px #ddd;
}

.featured-all .f-img .actions {
	position: absolute;
	top: 2px;
	right: 10px;
}

.featured-all .f-img .actions a {
	color: #72ff13;
	margin-left: 10px;
}

.header-list ul li ul.dropdown-inner {
	position: absolute;
	padding: 0;
	display: block !important;
	background: #fff;
	top: 40px;
	z-index: 9;
	width: 120px;
}
.profile_Sec .featured-all .dropdownon {
	position: absolute;
	top: 0;
	right: 22px;
}

.profile_Sec .featured-all .dropdown .btn {
	background: #fff;
	border: 0;
	padding: 0px 6px;
	appearance: none;
}
.profile_Sec .featured-all .dropdown .btn:hover {
	color: #000;
}
.profile_Sec .featured-all .dropdown .btn:after {
	display: none;
}
.profile_Sec
	.featured-all
	.dropdown
	.btn-secondary:not(:disabled):not(.disabled).active,
.profile_Sec
	.featured-all
	.dropdown
	.btn-secondary:not(:disabled):not(.disabled):active,
.profile_Sec .featured-all .dropdown.show > .btn-secondary.dropdown-toggle {
	color: #000;
}
.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}

.header-list ul li ul.dropdown-inner li {
	padding: 5px 0px;
}

.header-list ul li ul.dropdown-inner {
	position: absolute;
	padding: 0;
	display: none !important;
	background: #fff;
	top: 21px;
	z-index: 9;
	width: 120px;
	transition: 0.6s;
}

.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}

.header-list ul li ul.dropdown-inner li {
	padding: 5px 0px;
}

.header-list ul li:hover ul.dropdown-inner {
	display: block !important;
}

/* Responsive section  */
@media (min-width: 1441px) {
	.feature-icon-head span {
		font-size: 9px;
	}
}

@media (max-width: 1440px) {
	.chat-now {
		right: 12%;
		bottom: 3%;
	}

	.chat-now label {
		margin-right: 5px;
		font-size: 14px;
	}

	.slider-all {
		top: 180px;
	}
}

@media (max-width: 1400px) {
	.slider-all {
		top: 180px;
	}

	.header-list ul li {
		/* padding: 7px 16px; */
	}

	.car-dv {
		height: 140px;
	}

	.for-car1 :before {
		font-size: 82px;
		top: -6px;
		left: 51px;
	}

	.for-car2:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.for-car3:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.for-car4:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.feature-head h2 {
		font-size: 18px;
	}

	.feature-head p {
		font-size: 8px;
		white-space: nowrap;
	}

	.feature-icon-head span {
		font-size: 9px;
		padding-right: 13px;
	}

	.feature-iiimmgg span {
		font-size: 9px;
	}

	.new-listing h5 {
		font-size: 8px;
	}

	/* .checkup .btn {
		font-size: 10px;
		line-height: 60px;
	} */

	.see-all a {
		font-size: 12px;
	}

	.sell-head h2 {
		font-size: 74px;
		padding-bottom: 33px;
	}

	.sell-head h3 {
		font-size: 36px;
	}

	.slider-para p {
		font-size: 14px;
	}

	.john-doe h4 {
		font-size: 18px;
	}

	.john-doe h5 {
		font-size: 11px;
	}

	.footer-logo p {
		font-size: 12px;
	}

	.footer-head ul li {
		font-size: 11px;
	}

	.footer-para p {
		font-size: 11px;
	}

	.footer-input input {
		padding: 6px 16px;
		position: relative;
	}

	/* .footer-btn button {
		padding: 8px 30px;
	} */
	.footer-btn {
		right: -23px;
	}

	.copy-right p {
		font-size: 13px;
	}

	.footer-head h3 {
		font-size: 14px;
	}
}

@media (max-width: 1366px) {
	.slider-all {
		top: 160px;
	}

	.feature-icon-head span {
		font-size: 9px;
	}
}

@media (max-width: 1280px) {
	.slider-all {
		top: 145px;
	}
}

@media (max-width: 1200px) {
	.feature-icon-head span img {
		height: 20px;
		width: 19px;
	}

	.feature-icon-head span {
		font-size: 8px;
	}
}

@media (max-width: 1199px) {
	.header-list ul li {
		font-size: 11px;
	}

	.header-list ul li .dropdown .btn {
		font-size: 11px;
	}
}

@media (max-width: 1100px) {
	.HowItWorks .sliderx-img img {
		width: 310px;
		margin-left: -40px;
	}

	.slider-all {
		top: 100px;
	}
}

@media (max-width: 768px) {
	.slider-all {
		top: 100px;
	}

	.slider-input input {
		padding: 10px 30px;
	}

	.search-icon {
		top: 1px;
	}

	.for-car1:before {
		font-size: 73px;
		top: 4px;
		left: 31px;
	}

	.for-car2:before {
		font-size: 71px;
		top: 4px;
		left: 31px;
	}

	.for-car3:before {
		font-size: 73px;
		top: 4px;
		left: 31px;
	}

	.for-car4:before {
		font-size: 73px;
		top: 7px;
		left: 31px;
	}

	.feature-head {
		display: block;
		padding: 7px 0px;
	}

	.feature-normal-head h3 {
		font-size: 9px;
		line-height: 12px;
		padding-bottom: 0%;
	}

	.feature-icon-head span {
		font-size: 11px;
		padding-right: 13px;
	}

	/* .checkup .btn {
		font-size: 8px;
	} */
	/* .checkup .btn {
		padding: 5px 15px;
	} */
	.see-all {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	section.feature-sec {
		padding: 20px;
	}

	.feature-head h2 {
		font-size: 12px;
	}

	.feature-head h2 span {
		font-size: 8px;
		bottom: 3px;
	}

	.feature-icon-head span {
		font-size: 6px;
		padding-right: 6px;
	}

	.feature-iiimmgg span {
		font-size: 7px;
	}

	.feature-icon-head span img {
		padding: 6px 5px;
	}

	.feature-iiimmgg img {
		padding: 6px 5px;
	}

	.checkup .btn {
		line-height: 30px;
	}

	.see-all a {
		font-size: 9px;
		padding: 6px 27px;
	}

	.sell-head h3 {
		font-size: 20px;
	}

	.sell-head h2 {
		font-size: 38px;
	}

	.sell-head p {
		font-size: 8px;
	}

	.sell-btn a {
		font-size: 8px;
		padding: 6px 35px;
	}

	section.sell-sec {
		padding: 50px 0px;
	}

	.sell-head p {
		font-size: 16px;
		padding-bottom: 10px;
	}

	.testi h2 {
		font-size: 18px;
		padding: 30px;
	}

	.slider-para p {
		font-size: 7px;
	}

	.john-doe h4 {
		font-size: 11px;
	}

	.john-doe h5 {
		font-size: 6px;
	}
}

@media (max-width: 576px) {
	.slider-all {
		top: 60px !important;
	}

	.car-dv {
		height: 190px;
	}

	.slides.for1 {
		padding: 0px 18px;
	}

	.header-list ul li {
		font-size: 8px;
	}

	.feature-icon-head span {
		font-size: 16px;
	}

	.feature-iiimmgg span {
		font-size: 16px;
	}

	.sell-head p {
		font-size: 10px;
		padding-bottom: 10px;
	}

	section.sell-sec {
		padding: 36px 0px;
	}

	.slider-all {
		top: 37px;
	}

	.slider-input input {
		width: 84%;
		padding: 4px 19px;
	}

	.slider-para p {
		padding-top: 20px;
	}

	.slider-all input::placeholder {
		font-size: 10px;
	}

	/* .search-icon {
        left: 267px;
    } */
	.search-icon i {
		padding: 7px 7px;
	}
}

.button_setImage {
	border: none;
	border-radius: 50px;
	width: 50px;
	height: 50px;
}

.messanger_Img {
	width: 110px;
	height: 44px;
	/* border-radius: 28px; */
	object-fit: contain;
	/* border: 1px solid #00000036; */
}

.multi-imglist {
	list-style: none;
	display: flex;

	position: inherit;
	top: 55px;
}

.multi-imglist li {
	margin: 10px;
	cursor: pointer;
}
.images_set img {
	width: 44px;
	border-radius: 44px;
	height: 44px;
	object-fit: contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.img-fluid-my-class-set {
	height: 100px;
	width: 100%;
	margin-bottom: 5px;
	object-fit: cover;
}

/* End of Responsive section  */
.pagination .pageCount ul {
	display: flex;
	gap: 12px;
	list-style: none;
	align-items: center;
}

.pagination .pageCount ul li.previous,
.pagination .pageCount ul li.next {
	padding: 6px 13px;
	background: #81e574;
	color: #fff;
}

.pagination .pageCount ul li.previous.disabled,
.pagination .pageCount ul li.next.disabled {
	opacity: 0.6;
}

.pagination .pageCount ul li.selected {
	padding: 6px 13px;
	background: #72ff13;
	color: #000;
}
/* Banner Adds Css Start Here */
.add-banner {
	padding: 60px 0px;
}
.add-banner .heading-wrapper {
	padding-bottom: 20px;
	text-align: center;
}
.add-banner .heading-wrapper h3 {
	font-family: "monument-regular";
}
.add-banner .banner-slider {
	position: relative;
}
.add-banner .banner-slider .content-box {
	position: absolute;
	bottom: 0;
	height: 100%;
	padding: 60px 80px;
	display: flex;
	align-items: end;
}
.add-banner .banner-slider .content-box .inner-content {
	max-width: 60%;
}
.add-banner .banner-slider .img-box img {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: center center;
	filter: brightness(0.7);
}
.add-banner .banner-slider .content-box p {
	color: #fff;
	font-family: "Roboto";
}
.add-banner button.slick-arrow:before {
	color: #fff !important;
	opacity: 1;
}
/* Banner Adds Css End Here */

/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
	.car-dv {
		padding: 17px 10px 17px;
	}
	.tesla-model h5 {
		font-size: 11px;
		
	}
	.for-car1:before {
		font-size: 62px;
		
	}
	.for-car2:before {
		font-size: 62px;
	}
	.for-car3:before {
		font-size: 62px;
		
	}
	.for-car4:before {
		font-size: 62px;
	}
	.feature-normal-head h3 {
		font-size: 12px;
	}
	.BuyerTools {
		padding: 10px 0;
	}
	.see-all {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.add-banner {
		padding: 40px 0px;
	}
	.HowItWorks ~ section.contact .form-group {
		width: 100%;
	}
	.slider-para p {
		padding-left: 0px;
		padding-right: 0px;
	}
	.HowItWorks .sliderx-img img {
		margin-left: 0px;
	}
	.feature-normal-headds h3 {
		font-size: 10px;
		
	}
	.feature-icon-headds h6 {
		font-size: 10px;
	}
	.feature-sec .checkup button.btn {
		font-weight: 700;
		font-size: 8px;
		/* margin: 0px 12px; */
	}
	
}
@media (max-width:820px) {
}
@media (max-width: 768px) {
	.slider-input h4 {
		font-size: 20px;
	}
	.slider-all .searchBox .search-icon {
		bottom: unset;
		top: 30px;
	}
}
@media (max-width: 575px) {
	.feature-icon-head span {
		font-size: 12px;
	}
	.feature-iiimmgg span {
		font-size: 12px;
	}
	.feature-normal-head h3 {
		font-size: 10px;
	}
	.slider-para {
		padding: 4rem 1rem;
	}
	.Contact_us_head h4 {
		font-size: 24px;
	
	}
	.car-head h3 {
		text-align: center;
	}

	.slider-input h4 {
		font-size: 12px;
	}
	section.feature-sec {
		padding: 0px;
	}
	.footer-social-item ul li a {
		width: 41px;
		
	}
	footer .widget4 {
		margin: 0;
		display: table;
	}
}
/* ======================== */