/* Listing Css Start Here */
.lisiting {
	/* padding: 30px 0px; */
}
.lisiting .filter h4 {
	font-size: 16px;
	color: #202020;
}
.lisiting .filter p {
	font-size: 14px;
	color: #717171;
	font-family: "Poppins";
	font-weight: 500;
}
.lisiting .filter p i {
	color: #72ff13;
	font-size: 18px;
	margin-right: 5px;
}
.lisiting .filter ul.categories {
	padding: 0;
	list-style: none;
}
.lisiting .filter ul.categories li {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-top: 1px solid #eeeeee;
	padding: 9px 0;
	cursor: pointer;
	gap: 10px;
}
.lisiting .filter ul.categories li .dropdown button.btn {
    color: #717171;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    background: transparent;
    border: 0;
    padding: 0;
}
.lisiting .filter ul.categories li .dropdown button.btn:focus {
	box-shadow: unset;
}
.lisiting .filter ul.categories li span {
	color: #717171;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	font-family: "Poppins";
}
.lisiting .filter ul.categories li .form-group {
    display: flex;
    margin-bottom: 0;
}
.lisiting .filter ul.categories li .form-group .form-control {
    /* border-radius: 0; */
}
.lisiting .filter ul.categories li i.fa.fa-map-marker {
    color: #72ff13;
    font-size: 18px;
    margin-right: 5px;
}
.lisiting .view {
	display: flex;
	align-items: center;
	justify-content: end;
}
.lisiting .view label {
	font-size: 13px;
	font-family: "Montserrat";
	text-transform: uppercase;
	font-weight: 500;
	margin-right: 11px;
}
.lisiting .view img.img-fluid {
	width: 16px;
}
.lisiting .view .form-group {
	cursor: pointer;
}
.filter ul.categories {
	padding: 0px;
	list-style: none;
}

.filter ul.categories li {
	display: flex;
	justify-content: space-between;
	padding: 10px 0px;
	border-top: 2px solid #eeeeee;
	color: #717171;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	align-items: center;
}
/* .lisiting {
	padding: 30px 0px;
} */
.filter .heading h4 {
	color: #202020;
	font-size: 18px;
	font-weight: 500;
	font-family: "Poppins";
}
.filter p i {
	color: #72ff13;
}
.filter p {
	font-size: 16px;
	font-family: "Poppins";
	color: #717171;
}
.feature-sec .featured-all {
	margin-bottom: 50px;
}
/* .lisiting .f-img img {
	height: 180px;
	object-fit: cover;
} */
.lisiting .feature-sec {
	padding: 0;
}
.featured-all .f-img ul.slick-dots {
	bottom: 5px;
}
.featured-all .f-img ul.slick-dots li {
	width: 7px;
}
.featured-all .f-img ul.slick-dots li button:before {
	color: #fff;
	opacity: 1 !important;
	margin: 0 !important;
}
/* Listing Css End Here */

.slick-dots li button:before {
	font-family: "slick";
	font-size: 3px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: "•";
	text-align: center;
	opacity: 0.25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {

	
}
@media (max-width:820px) {
}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
	
}
/* ======================== */