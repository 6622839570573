.cardetail-sec {
	padding: 100px 0px 50px 0px;
}
.cardetail-sec .detail-img-wrapper span.no {
	font-size: 32px;
	font-family: "Montserrat";
	font-weight: 500;
	color: #72ff13;
}

.form_control_text {
	border: none;
	background: #f1f1f1;
	box-shadow: none;
	outline: none;
	border-radius: 5px;
	color: rgb(46, 44, 44);
	padding: 12px 15px;
}

.cardetail-sec .img-and-btn-wrapper .detail-img-wrapper {
	position: relative;
}
.cardetail-sec .img-and-btn-wrapper .detail-img-wrapper figure img {
	width: 100%;
	height: 600px;
}
.cardetail-sec .detail-img-wrapper span.no {
	position: absolute;
	bottom: 2.5%;
	right: 15%;
}
.cardetail-sec .btn-wrapper {
	position: absolute;
	right: 10%;
	bottom: 6%;
}
.cardetail-sec .img-and-btn-wrapper button.slick-arrow.slick-prev {
	display: none !important;
}
.cardetail-sec .img-and-btn-wrapper button.slick-arrow.slick-next {
	position: absolute;
	top: 92%;
	transform: translate(110%, -50%);
	background: #72ff13;
	width: 32px;
	height: 34px;
}
.cardetail-sec .img-and-btn-wrapper .slick-next:before {
	font-size: 15px !important;
}
.top-btn-wrapper button.btn {
	font-weight: 700;
	font-size: 11px;
	padding: 7px 14px;
}
.top-btn-wrapper button.btnBlack:hover {
	background: transparent;
	border: 1px solid #000;
	color: #000;
}
.top-btn-wrapper button.btnBlack {
	font-size: 11px;
	font-family: "Montserrat";
	background-color: #212121;
	color: #ffffff;
	padding: 7px 14px;
	border: 1px solid #212121;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
	font-weight: 700;
	border-radius: 0.25rem;
	margin-top: 0px;
}
.top-btn-wrapper button.btngrey {
	font-size: 11px;
	font-family: "Montserrat";
	background-color: #f5f5f5;
	color: #505050;
	padding: 7px 14px;
	border: 1px solid #f5f5f5;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
	font-weight: 700;
	border-radius: 0.25rem;
	margin-top: 0px;
	border: 1px solid #505050;
}
.top-btn-wrapper button.btngrey:hover {
	background-color: #72ff13;
	border: 1px solid #72ff13;
	color: #fff;
}
.detail-content-wrapper .top-btn-wrapper button.btn.btnrws:hover {
	border: 1px solid transparent;
	box-shadow: rgb(114 255 19) 0px 0px 8px;
	color: #000;
}

.top-btn-wrapper button {
	margin: 0px 3px;
}
.detail-content-wrapper .top-btn-wrapper {
	display: flex;
	align-items: center;
	gap: 30px;
}
.top-btn-wrapper span.price {
	display: block;
}
.detail-content-wrapper .top-btn-wrapper h4 {
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 700;
}
.detail-content-wrapper .top-btn-wrapper h4 span.price {
	color: #72ff13;
	font-family: "Montserrat";
	font-size: 30px;
}
.modal_center_success img {
	text-align: center;
	margin: 0 auto;
	display: flex;
	width: 200px;
	margin-top: -45px;
}
.modal_center_success h5 {
	margin: 10px auto;
	text-align: center;
	color: #606060;
	font-weight: 700;
	text-transform: capitalize;
}
.form_content_detail {
	text-align: center;
	word-spacing: 1.2px;
	margin: 0 auto;
	margin-top: -40px;
	text-align: justify;
}

.modal_all_container {
	position: relative;
}

.position_set_cancel {
	position: absolute;
	top: 5px;
	right: 10px;
	cursor: pointer;
}

.form_content_detail p {
	color: #606060;
}

.form_content_detail span {
	color: #606060;
	font-weight: 500;
	text-transform: capitalize;
}

.title-name-wrapper h2 {
	font-family: "Montserrat";
	font-size: 26px;
	font-weight: 700;
	color: #2a2a2a;
}
.title-name-wrapper {
	margin: 10px 0px;
}
.cardetail-sec .detail-content-wrapper h5 {
	color: #000000;
	font-family: "Montserrat";
	font-size: 14px;
}
.cardetail-sec .detail-content-wrapper h5 i {
	color: #b1b1b1;
}
.detail-content-wrapper .stats-wrapper {
	display: flex;
}
.detail-content-wrapper .stats-wrapper h5 {
	margin: 0px 12px 0px 0px;
	/* color: #b9b9b9; */
	color: #000;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.detail-content-wrapper .stats-wrapper h5 span {
	display: block;
	color: #000000;
	font-size: 14px;
	font-weight: 500;
}
.cardetail-sec .highlights-wrapper {
	margin: 16px 0px;
}
.detail-content-wrapper .highlights-wrapper h4 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.detail-content-wrapper .highlights-list-wrapper ul {
	padding: 0px;
	column-count: 2;
	list-style: none;
}
.detail-content-wrapper .highlights-list-wrapper ul li {
	font-size: 13px;
	/* color: #aeaeae; */
	color: #000;
	font-family: "Poppins";
	line-height: 2;
}
.detail-content-wrapper .highlights-list-wrapper ul li img {
	opacity: 0.5;
}
.equip-list-wrapper ul li img {
	opacity: 0.5;
}
.detail-content-wrapper .highlights-list-wrapper ul li i {
	background: #000000;
	padding: 2px;
	border-radius: 50%;
	font-size: 5px;
	color: #fff;
	font-weight: 400 !important;
	margin-right: 8px;
}
.cardetail-sec .seller-snap-wrapper p {
	/* color: #b9b9b9; */
	color: #000;
	font-size: 12px;
	font-family: "Montserrat";
}
.cardetail-sec .seller-snap-wrapper {
	background: #d6fcd1;
	padding: 20px;
	/* padding: 2rem; */
}
.vehicles_vin {
	margin: 0 auto !important;
}

.cardetail-sec .seller-heading-wrapper h5 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.car-specification .specs-wrapper h6 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 700;
}
.specs-wrapper ul li span.property {
	/* color: #aeaeae; */
	color: #000;
	font-family: "Poppins";
	font-weight: 400;
}
.specs-wrapper ul {
	list-style: none;
	padding: 0px;
	padding-top: 17px;
}
.specs-wrapper ul li {
	display: flex;
	justify-content: space-between;
}
.specs-wrapper ul li span.value {
	font-size: 14px;
	color: #4d4d4d;
	font-family: "Poppins";
	font-weight: 500;
}
.equip-list-wrapper ul {
	padding: 0px;
	list-style: none;
	column-count: 4;
}
.equip-list-wrapper ul li {
	font-family: "Poppins";
	/* color: #9a9a9a; */
	color: #000;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.8;
}
.equip-list-wrapper {
	padding-top: 19px;
}
.EQUIPMENT-sec {
	padding: 30px 0px;
	background: #f5f5f5;
	margin-top: 30px;
}
.EQUIPMENT-sec .equipment-heading-wrapper h5 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.feature-sec .checkup button.btn {
	font-weight: 700;
	font-size: 14px;
}
.BuyerTools {
	padding: 50px 0;
}
.BuyerTools h4 {
	font-family: "Montserrat";
	font-size: 27px;
	font-weight: 700;
	color: #2a2a2a;
}
.buyer-card-wrapper .buyer-content-wrapper h5 {
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 700;
	color: #2a2a2a;
	margin: 8px 0;
}
section.BuyerTools .buyer-img-wrapper {
	overflow: hidden;
	height: 200px;
	border-radius: 10px;
}

section.BuyerTools .buyer-card-wrapper .buyer-img-wrapper img:hover {
	transform: scale(1.5);
	transition: 0.5s;
}
section.BuyerTools .buyer-card-wrapper .buyer-img-wrapper img {
	width: 100%;
	height: 200px;
}

/* modal css  */
div#battery_health .modal-body .progress {
	/* margin-bottom: 1rem; */
	background: #fff;
	height: 40px;
	border-radius: 0;
}
div#battery_health .progress_values {
	display: flex;
	justify-content: space-evenly;
}
#battery_health .progress-bar {
	background-color: #72ff13;
	position: relative;
	height: 30px;
}
div#battery_health .modal-body .col-md-4 p {
	text-align: right;
	margin-bottom: 0;
	font-family: "Poppins";
	font-size: 14px;
	color: #000;
}
div#battery_health .progress_values p {
	font-family: "Poppins";
	font-size: 14px;
	font-weight: bold;
	color: #000;
}
div#battery_health .progress span {
	font-family: "Poppins";
	font-weight: bold;
	font-size: 14px;
	/* position: absolute; */
	/* right: 0px; */
	color: #000;
	margin-left: 5px;
	line-height: 2;
}
div#battery_health .modal-body .col-md-4:not(:last-child) {
	border-right: 3px solid #bab8b8;
}
div#battery_health .modal-header {
	border-bottom: 0;
}
@media (max-width: 1200px) {
	.cardetail-sec .seller-snap-wrapper {
		padding: 1rem;
	}
	.detail-content-wrapper .highlights-list-wrapper ul li {
		font-size: 11px;
	}
	.cardetail-sec .img-and-btn-wrapper .detail-img-wrapper figure img {
		width: 100%;
		height: 518px;
	}
}
@media (min-width: 768px) {
	div#battery_health .modal-dialog {
		max-width: 900px;
	}
}
/* modal css  */

/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {


}
@media (max-width:820px) {
}
@media (max-width: 768px) {

}
@media (max-width: 575px) {

	.feature-sec .checkup button.btn {
		font-weight: 700;
		font-size: 14px;
		width: 100%;
	}
	.pageCount button {
		font-family: "Poppins";
		font-size: 10px;
	}
}
/* ======================== */
