.PostVehicle {
	background: linear-gradient(0deg, #d7d7d7, transparent),
		url("../img/post1.png");
	/* background-image: url("../img/post1.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 100px 0px;
}
.PostVehicle h4 {
	font-family: "Poppins";
	text-align: center;
	font-size: 32px;
	font-weight: 700;
	color: #1a1a1a;
}

.set_Images img {
	max-width: 80%;
	object-fit: contain;
}
#createProductFormImage {
	width: 100%;
	overflow: auto;
}

#createProductFormImage > img {
	width: 8vmax;
	margin: 0 0.5vmax;
}
/* .PostVehicle .sc-dkzDqf.fVfcRr {
	display: none;
} */
.PostVehicle label svg {
	display: none;
}
.form-switch .form-check-input {
	margin-left: 0px !important;
}
.form-switch .form-check-input:checked {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
	background-position: right center !important;
	background-size: 14px !important;
	background-repeat: no-repeat !important;
	background-color: #fff !important;
}
.PostVehicle .dGSgsW {
	width: auto;
	min-width: 100%;
	height: 153px;
	border: 0px;
	background-color: #fff;
	background-image: url("../img/Icon\ ionic-md-photos.png");
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 13px;
}
.PostVehicle .fVfcRr .file-types {
	display: none;
}
.PostVehicle .fVfcRr {
	position: absolute;
	bottom: 3px;
	text-align: center;
}
.PostVehicle form {
	margin-top: 37px;
}
.PostVehicle form .form-control {
	padding: 10px;
	border: 0px;
	font-family: "Montserrat";
	font-size: 16px;
	color: #808080;
	margin-bottom: 10px;
}
.PostVehicle form .form-control::-webkit-file-upload-button {
	display: none;
}
.PostVehicle h5 {
	color: #1e1e1e;
	font-family: "Montserrat";
	font-size: 20px;
	font-weight: 600;
}
.PostVehicle .form-heading-wrapper {
	padding: 15px 0px;
}
.PostVehicle label {
	/* color: #808080; */
	color: #000;
	font-family: "Montserrat";
	font-size: 16px;
	font-weight: 400;
	min-width: 100%;
}
.PostVehicle .file-wrapper {
	padding: 20px 0px;
}
.PostVehicle .form-check-input:checked {
	background: transparent;
	border: 1px solid;
	box-shadow: none;
}
.PostVehicle .form-check-input:checked[type="radio"]::after {
	content: "";
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	background: black;
	top: 22%;
	left: 22%;
	border-radius: 50%;
}
.PostVehicle button {
	font-weight: 500;
	padding: 10px 29px;
}
.postSnap form .form-control {
	background: #f5f5f5;
}
.postSnap .dGSgsW {
	background-color: #f5f5f5;
}
.postSnap h6 {
	color: #1e1e1e;
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 600;
}
.postSnap .form-check .form-check-input {
	border-radius: 50%;
}
.postSnap .form-check-input:checked {
	background: #72ff13;
	border: 1px solid #72ff13;
	box-shadow: none;
}
.postSnap .form-check-input[type="checkbox"]:checked::after {
	content: "\f00c";
	font-family: "FontAwesome";
	position: relative;
	top: -7px;
	font-size: 12px;
	left: 1px;
}
.PostVehicle .file-wrapper label.sc-bczRLJ.kiHXWb {
	/* background-color: #fff  !important; */
	/* background:#fff,url(../img/imageicon.png) ; */
	background-color: #fff;
	background-image: url(../img/imageicon.png);
	min-width: unset;
	max-width: 100%;
	background-position: center;
	height: 140px;
	background-repeat: no-repeat;
	background-size: 60px;
	border: 0;
}
.PostVehicle .file-wrapper label.sc-bczRLJ.kiHXWb span.sc-hKMtZM.cXBrtO {
	visibility: hidden;
}
.PostVehicle .file-wrapper .kTMxfv span {
	display: none;
}
