.loader-full {
	position: fixed;
	z-index: 333;
	background: #e0e0e0f0;
	width: 100vw;
	height: 100vh;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-full .spinner-border {
	width: 150px;
	height: 150px;
	border-color: #43f22c;
	border-right-color: transparent;
	border-width: 7px;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border 0.75s linear infinite;
	animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}

	50% {
		opacity: 1;
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: spinner-grow 0.75s linear infinite;
	animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}
