/* All Dealer Css Start Here */
.dealer_sec {
	padding: 60px 0 20px;
}
.dealer_sec .heading {
	text-align: center;
	padding-bottom: 20px;
}
.dealer_sec .heading h3 {
	color: #434344;
	text-transform: uppercase;
	font-family: "Poppins";
	font-weight: 700;
}
.dealer_sec .filterDv {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}
.dealer_sec .filterDv figure {
	margin: 0;
}
.dealer_sec .filterDv .form-group {
	margin: 0;
}
.dealer_sec .filterDv .form-group .form-control {
	color: #434344;
	border: 0;
	font-family: "Poppins";
	font-weight: 600;
	margin-left: 10px;
	box-shadow: none;
	cursor: pointer;
	padding: 0 20px 0 0;
	background-image: url("../img/angel.png");
	background-repeat: no-repeat;
	background-position: center right;
	background-color: transparent;
}
.dealer_sec .dealer-box {
	display: flex;
	margin-bottom: 50px;
}
.dealer_sec .dealer-box figure img {
	width: 170px;
}
.dealer_sec .dealer-box .content {
	margin-left: 15px;
}
.dealer_sec .dealer-box .content h4 {
	color: #434344;
	font-family: "Poppins";
	font-size: 18px;
	font-weight: 600;
	margin: 0 0 5px;
}
.dealer_sec .dealer-box .content p {
	font-family: "Poppins";
	font-size: 15px;
	/* color: #9b9b9b; */
	color: #000;
}
.dealer_sec .dealer-box .content .button-group a {
	background: #72ff13;
	color: #000;
	font-family: "Poppins";
	font-size: 12px;
	font-weight: 500;
	border-radius: 5px;
	padding: 9px 30px;
	text-decoration: none;
}
/* All Dealer Css End Here */
