header#header {
	position: relative;
	background: #515151;
	/* background-image: url(../img/all-line.webp); */
}

section.main-sec {
	background-color: #ededef;
}

.Head-order {
	text-align: center;
	/* justify-content: center; */
	margin: 100px 0;
	text-transform: uppercase;
}

.main-top h2 {
	width: 100%;
	text-align: center;
	height: 110px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: "visby-semibold";
	font-size: 39px;
	color: #3a3a3a;
	margin: 0;
	text-transform: uppercase;
}

/* .detail-order {
	margin-top: 71px;
	margin-bottom: 100px;
} */

.order-detail-wrapper {
	padding: 30px 20px;
	border: 1px solid #b4b4b4;
	border-radius: 9px;
}
.cokkies-order {
	display: flex;
	align-items: end;
}

.Card_height {
	height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.Card_height::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(68, 249, 44, 0.3);
	background-color: #f5f5f5;
}

.Card_height::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

.Card_height::-webkit-scrollbar-thumb {
	background-color: #44f92c;
	/* border: 2px solid #555555; */
}

.quantity-order {
	margin-right: 13px;
}

.cokkies-order1 {
	display: flex;
	align-items: center;
	justify-content: end;
}

.details-child ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 1.6;
	color: #0c0200;
	font-family: "visby-med";
	font-size: 19px;
}

.details-child ul {
	padding: 0;
}

.order-detail-cancel {
	position: absolute;
	top: -4px;
	right: 1px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	outline: none;
	border: none;
	background: transparent;
	font-size: 22px;
}

.cokkies-order img.img-fluid {
	width: 94px;
	object-fit: contain;
	height: 70px;
}

.cokkies-order h6 {
	color: #515151;
	font-family: "visby-semibold";
	font-size: 15px;
}

.cokkies-order h5 {
	color: #44f92c;
	font-family: "visby-med";
	font-size: 16px;
}

/* .quantity-order {
	margin-left: 9px;
} */

.cokkies-order .quantity-order {
	padding-left: 12px;
}

.cokkies-order1 h6 {
	color: #515151;
	font-family: "visby-semibold";
	font-size: 21px;
	text-align: center;
}
.cokkies-order1 h5 a {
	color: #44f92c;
	font-family: "visby-med";
	font-size: 16px;
	text-decoration: revert;
}

.detail-order h4 {
	text-align: center;
	color: #0c0200;
	font-family: "visby-semibold";
	font-size: 38px;
	margin-bottom: 25px;
}

.orderdetail h5 {
	color: #44f92c;
	font-family: "visby-med";
	font-size: 19px;
}

.orderdetail {
	margin-top: 30px;
	margin-bottom: 20px;
}
@media (max-width: 575px) {
	.order-detail-wrapper {
		padding: 30px 10px;
		border: 1px solid #b4b4b4;
		border-radius: 9px;
	}
}
