.buyerstool_page .buyer-card-wrapper .buyer-img-wrapper img {
	width: 100%;
}
.buyerstool_page .buyerstool_desc {
	padding: 1rem 0;
}
.buyerstool_page .buyerstool_desc p {
	color: #000;
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 300;
	padding-bottom: 0px;
}

#exampleModalCenter .form-group label {
	font-size: 15px;
	color: rgb(31, 29, 29);
	line-height: 35px;
}
#exampleModalCenter .form-group .form-control {
	border: none;
	background: #f1f1f1;
	box-shadow: none;
	outline: none;
	border-radius: 5px;
	color: rgb(46, 44, 44);
	padding: 12px 15px;
}
#exampleModalCenter .form-group textarea {
	height: 100px;
	resize: none;
}
#exampleModalCenter .form-group .form-control::placeholder {
	color: #494646d9;
	font-size: 12px;
}
#exampleModalCenter .form-group {
	margin-bottom: 20px;
}
#exampleModalCenter .button-group {
	margin-top: 24px;
	text-align: end;
}
.react-datepicker__input-container input {
	border: none;
	background: #f1f1f1;
	box-shadow: none;
	outline: none;
	border-radius: 5px;
	color: rgb(46, 44, 44);
	padding: 12px 15px;
}
