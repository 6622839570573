/* Faqs Css Start Here */
/* .bannerSec {
	background-image: url(../img/blog2.webp);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 193px 0;
	background-position: "bottom";
} */

.bannerSec {
	/* background-image: url("../img/back-faq.png"); */
	/* background: linear-gradient(#acabab5e, #acabab5e), url(../img/blog2.webp); */
	background-repeat: no-repeat !important;
	background-size: 100%;
	/* padding: 193px 0; */
	height: 600px;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.bannerSec .content_wrapper {
	max-width: 40%;
}
.bannerSec .content_wrapper h2 {
	padding-bottom: 15px;
	color: #ffffff;
	font-family: "monument-bold";
	line-height: 40px;
	font-size: 30px;
	letter-spacing: 4px;
	text-shadow: 2px 2px #000000;
}
.bannerSec .content_wrapper .searchBox {
	display: flex;
	position: relative;
	width: 500px;
}
.bannerSec .content_wrapper .searchBox .form-control {
	border-radius: 50px;
	padding: 17px 25px;
	font-family: "montserrat";
	border: 0;
}
.bannerSec .content_wrapper .searchBox button.btn {
	position: absolute;
	right: 6px;
	top: -3px;
	padding: 0;
	background: transparent;
	border: 0;
}
.faqs {
	padding: 80px 0 0px;
}
.faqs .accordion-item .accordion-header {
	background: transparent;
	border-bottom: 1px solid #cfcfcf;
}
.faqs .accordion-item {
	border: 0;
	background: transparent;
}
.faqs .accordion-item .accordion-header button.accordion-button {
	background: transparent;
	outline: none;
	box-shadow: none;
	padding: 15px 0px;
	color: #000;
	font-family: "Montserrat";
}
.faqs .accordion-item .accordion-body {
	padding: 15px 20px 10px;
	font-family: "Montserrat";
	font-size: 15px;
	color: #000;
}
.faqs .accordion-item button.accordion-button:after {
	background-image: url("../img/electricity.webp");
	opacity: 0.5;
}
/* Faqs Css End Here */
@media (max-width: 1440px) {
	.bannerSec {
		padding: unset;
	}
}
@media (max-width: 1366px) {
	.bannerSec .content_wrapper {
		max-width: 40%;
		margin-left: 70px;
	}
	.bannerSec {
		padding: 132px 0;
	}
}
@media (max-width: 1280px) {
	.bannerSec {
		padding: 119px 0;
	}
}
@media (max-width: 1200px) {
	.bannerSec {
		padding: 106px 0;
	}
}
@media (max-width: 1100px) {
	.bannerSec {
		padding: 70px 0;
	}
}

/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {


}
@media (max-width:820px) {
}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
	.bannerSec {
		height: 230px;
	
	}
	.bannerSec .content_wrapper {
		margin-left: 0px;
	}

}
/* ======================== */