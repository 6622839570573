.bannerads_sec .right-side .ads_date_dv p {
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}


.bannerads_sec .right-side .ads_date_dv:not(:last-child) {
    border-right: 1px solid #cdcbcb;
}

.bannerads_sec .right-side .ads_date_dv:not(:last-child) {
    border-right: 1px solid #000;
}

.bannerads_sec .adsbannername .form-group {
    position: relative;
}

.bannerads_sec .adsbannername .form-group .form-control~button.btn.copylink {
    position: absolute;
    right: 8px;
    bottom: 5px;
    padding: 6px 20px;
    margin-top: 0;
}

.bannerads_sec .adsbannername .form-group .form-control {
    line-height: 2;
}

.bannerads_sec .redaleart span {
    color: #cb7f6a;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 500;
    margin-top: 0;
}

.bannerads_sec .redaleart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .78rem 1rem;
    background: #ffbfbf;
    border: 1px solid #cb7f6a;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.bannerads_sec .adsbannername h6 {
    /* margin-bottom: 0; */
}

.bannerads_sec .bannerbg {
    background: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
}

.bannerads_sec .right-side .title .snap-chat span {
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

section.profile_Sec.bannerads_sec .right-side .button-group a.btn {
    width: 100%;
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

/* advertise your banner css start here */

.advertbanner_sec .fileUpload label.form-label i.fa.fa-paperclip {
    background-color: #72ff13;
    border: none;
    color: white;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
}
.advertbanner_sec .form-control {
    color: #656565;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}
.advertbanner_sec .fileUpload label.form-label #uploadBtn {
    position: absolute;
    opacity: 0;
}

.advertbanner_sec .fileUpload label.form-label span {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.advertbanner_sec .fileUpload .form-label {
    display: flex;
}

.advertbanner_sec .perdaycrgssec {
    background: #cfc8c8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.advertbanner_sec label {
    color: #656565;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

.advertbanner_sec span {
    color: #656565;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

.advertbanner_sec .perdaycrgssecs {
    background: #cfc8c8;
    border-radius: 4px;
    padding: 1rem 2rem;
}

.advertbanner_sec .bannerdur {
    display: flex;
    justify-content: space-between;
}

.advertbanner_sec .button-group .btn {
    width: 100%;
    color: #000;
    font-family: "Poppins";
    font-weight: 500;
}
.advertbanner_sec .form-select {
    color: #656565;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
}
/* advertise your banner css end here */
/* payment banner css start here */
.banneradpayment_sec .card-type {
    display: flex;
    max-width: 50%;
}

/* Saved Payment Css Start Here */


.banneradpayment_sec label.container {
    padding: 0;
}

.banneradpayment_sec label.container span.checkmark .card-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #70707099;
    padding: 10px 20px;
    position: relative;
    border-radius: 10px;
}

.banneradpayment_sec label.container input:checked~span.checkmark .card-details {
    box-shadow: 0px 0px 0px 2px #c23127;
    border-color: #fff;
}

.banneradpayment_sec label.container span.checkmark .card-details .visa-card {
    display: flex;
    align-items: center;
    padding-left: 40px;
}

.banneradpayment_sec label.container span.checkmark .card-details .visa-card figure {
    margin-bottom: 0;
}

.banneradpayment_sec label.container span.checkmark .card-details .visa-card .input-box label {
    color: #000000;
    font-size: 8px;
    font-weight: 600;
}

.banneradpayment_sec label.container span.checkmark .card-details .visa-card input {
    border: 0;
    height: unset;
    font-size: 18px;
    color: #343a40;
    font-weight: 600;
}

.banneradpayment_sec label.container span.checkmark .card-details .extra-btn {
    display: flex;
    align-items: center;
}

.banneradpayment_sec label.container span.checkmark .card-details .extra-btn button.btn {
    margin-right: 20px;
    background: #e7e7e7;
    color: #7d7d7d;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
}

.banneradpayment_sec label.container.second-card button.btn.default.disabled {
    visibility: hidden;
}

.banneradpayment_sec label.container span.checkmark .card-details .extra-btn .selection {
    opacity: 0;
}

.banneradpayment_sec label.container input:checked~span.checkmark .card-details .extra-btn .selection {
    opacity: 1;
}

.banneradpayment_sec label.container span.checkmark .card-details .extra-btn .selection i {
    background: #0eb702;
    color: #fff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

.banneradpayment_sec label.container input[type="radio"] {
    opacity: 0;
}

.banneradpayment_sec label.container span.checkmark .card-details .radio-btn span {
    background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #f35d63;
    top: 27.5px;
}

.banneradpayment_sec label.container input:checked~span.checkmark .card-details .radio-btn span {
    background: #c23127;
}

.checkout .add-new-address label.container span.checkmark {
    background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #f35d63;
    top: 24.5px;
}

.banneradpayment_sec .add-new-method label.container span.checkmark {
    border: 2px solid #fff;
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    left: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px #f35d63;
    top: 33px;
}

.banneradpayment_sec .add-new-method {
    position: relative;
}

.banneradpayment_sec .add-new-method label.container {
    padding-left: 30px;
    font-size: 15px;
    color: #262626;
    font-weight: 700;
    padding-bottom: 15px;
}

.banneradpayment_sec .add-new-method {
    padding-top: 30px;
}

.banneradpayment_sec .add-new-method label.container input:checked~span.checkmark {
    background: #c23127;
}

.banneradpayment_sec .add-new-method .form-group .form-control {
    height: 50px;
    border-color: #70707059;
    border-radius: 50px;
    padding: 0 30px;
}

.banneradpayment_sec .add-new-method .form-group .form-control::placeholder {
    color: #55555578;
    font-weight: 400;
    font-size: 15px;
}

.banneradpayment_sec .add-new-method button {
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
    background: #c23127;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    border: 2px solid #fff;
    transition: 0.6s;
}

.banneradpayment_sec .add-new-method button:hover {
    box-shadow: 0px 0px 0px 2px #c23127;
}
.banneradpayment_sec .adbannerdetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.banneradpayment_sec .adbannerdetail.bord {
    border-bottom: 1px dotted;
    padding: 1rem 0;
}
.banneradpayment_sec {
    position: relative;
    background: #f5f5f5;
    padding: 30px 20px;
    height: 100%;
}
.adbannermain {
    background: #fff;
    padding: 1rem;
    border-radius: 4px;
}
.banneradpayment_sec .button-group button.btn {
    margin-top: 10px;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
}


.banneradpayment_sec div#editbannerid .modal-body {
    text-align: center;
}

.banneradpayment_sec div#editbannerid .modal-body h4 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Poppins';
}
.banneradpayment_sec div#exampleModalToggle .modal-body {
    text-align: center;
}

.banneradpayment_sec div#exampleModalToggle .modal-body h4 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Poppins';
}
/* Saved Payment Css Start Here */
/* payment banner css end here */