/* Dealer Css Start Here */
.dealer_sec {
	padding: 60px 0 20px;
	background-image: url("../../Assets/img/dealer-back.png");
}
.dealer .dealer-form {
	box-shadow: 0px 0px 10px #ddd;
	padding: 30px 30px;
	border-radius: 8px;
	margin-top: -100px;
	background: #fff;
}
.dealer .heading h3 {
	font-size: 30px;
	font-family: "Monument Extended", sans-serif;
}

/* .set_Images img {
	position: relative;
	top: 125px;
	left: 75px;
} */

.dealer ul.list {
	padding: 15px 0 0 0;
	margin: 0;
}
.dealer ul.list h4 {
	color: #434344;
	font-family: "Poppins";
	font-size: 27px;
	font-weight: 600;
	margin-bottom: 30px;
}
.dealer ul.list li {
	list-style: none;
	font-family: "Montserrat";
	color: #000000;
	font-weight: 500;
	margin-bottom: 10px;
	margin-left: 10px;
}
.dealer ul.list li img {
	width: 15px;
	margin-right: 8px;
}
.actionLink .contactDv {
	display: flex;
	align-items: end;
	transform: translateX(100%);
	transition: 0.6s;
	position: relative;
	z-index: 2;
}
.dealer .dealer-form .form-group .form-control {
	background: #f5f5f5;
	border-radius: 0;
	font-family: "Montserrat";
	font-size: 14px;
	border: 0;
	padding: 17px 20px;
}
.dealer .dealer-form .checkk {
	margin-left: 20px;
	position: relative;
}
.dealer .dealer-form .checkk input#checK {
	width: unset;
	background: transparent;
	border: 2px solid #44f92c;
	padding: 8px;
}
.dealer .dealer-form .checkk label {
	margin-left: 20px;
	color: #a5a5a5;
	font-family: "Montserrat";
	font-size: 15px;
	line-height: 21px;
}
.dealer .dealer-form .button-group button {
	background: #44f92c;
	font-family: "Poppins";
	font-weight: 500;
	padding: 11px 60px;
	color: #484848;
}
.dealer .dealer-form .checkk input#user {
	border: 2px solid #44f92c;
	box-shadow: none;
	outline: none;
}
/* Dealer Css End Here */
/* Begin of Responsive */

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {

	
}
@media (max-width:820px) {
}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
	.dealer .dealer-form {
		padding: 30px 20px;
		margin-top: -30px;
	}
	.checkk22 label {
		font-size: 10px;
		
	}

}
/* ======================== */