/* Profile Css Start Here */
.profile_Sec {
	padding: 50px 0;
}
.profile_Sec .sidebar {
	background: #f5f5f5;
	height: 550px;
	padding: 30px 20px;
	position: relative;
}
.Profile_controller input {
	background: none !important;
}
.profile_Sec .sidebar .heading {
	padding-bottom: 15px;
}
.profile_Sec .sidebar .heading h4 {
	font-family: "Poppins";
	font-size: 16px;
	color: #989898;
}
.profile_Sec .sidebar ul.navs {
	padding: 0;
	list-style: none;
}
.profile_Sec .sidebar ul.navs li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #d1d1d154;
	padding: 10px 0px;
	text-decoration: none;
}
.profile_Sec .sidebar ul.navs li span {
	color: #656565;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec .sidebar ul.navs li img.img-fluid {
	width: 15px;
}
.profile_Sec .sidebar .logout-btn {
	position: absolute;
	bottom: 20px;
	left: 20px;
}
.profile_Sec .sidebar .logout-btn button.btn {
	background: transparent;
	border: 0;
	padding: 0;
	color: #000;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec .right-side .head {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.profile_Sec .right-side .head .edit-btn a {
	color: #2382ff;
	font-family: "Poppins";
}
.profile_Sec .right-side .head .name-image {
	display: flex;
	align-items: flex-start;
	padding-right: 40px;
}
.profile_Sec .right-side .head .name-image figure img {
	max-width: 100px;
	margin-right: 20px;
}
.profile_Sec .right-side .head .name-image .name h4 {
	font-family: "Poppins";
	color: #4d4d4d;
	font-weight: 600;
}
.profile_Sec .right-side .head .name-image p {
	font-size: 15px;
	font-family: "Poppins";
	/* color: #8a8a8a; */
	color: #000;
	line-height: 23px;
}
.profile_Sec .right-side .title {
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.profile_Sec .right-side .title button {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}
.profile_Sec .right-side .title .snap-chat .dropdown button {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
	outline: none;
	box-shadow: none;
}
.profile_Sec .right-side .title .snap-chat {
	display: flex;
	gap: 20px;
}
.profile_Sec .right-side .title .snap-chat .dropdown {
	background: #535353;
	border-radius: 5px;
}
.profile_Sec .right-side .title h3 {
	font-size: 32px;
	font-family: "Poppins";
	font-weight: 700;
}
.profile_Sec .right-side .user-detail {
	padding-top: 10px;
}
.profile_Sec .right-side .user-detail h4 {
	font-size: 16px;
	color: #989898;
	font-family: "Poppins";
	padding-bottom: 5px;
}
.profile_Sec .right-side .user-detail table.table tr {
	border-top: 1px solid #d9d9d9 !important;
}
.profile_Sec .right-side .user-detail table.table tr td {
	border: 0;
	vertical-align: middle;
	padding: 15px;
}
.profile_Sec .right-side .user-detail .table-responsive {
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px 10px;
}
.profile_Sec .right-side .user-detail .table-responsive table.table th {
	vertical-align: middle;
	color: #111111;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 15px;
	border-top: 0;
}
.profile_Sec
	.right-side
	.user-detail
	.table-responsive
	table.table
	.form-control {
	color: #8a8a8a;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 14px;
	padding: 0;
	border: 0;
	outline: none;
	box-shadow: none;
}
.profile_Sec .right-side .user-detail .table-responsive table.table a {
	font-family: "Poppins";
	font-weight: 400;
	font-size: 14px;
}
.profile_Sec
	.right-side
	.user-detail
	.table-responsive
	table.table
	td:last-child {
	text-align: end;
}
.profile_Sec .right-side .user-detail table.table tr:first-child {
	border: 0 !important;
}
.profile_Sec .right-side .user-detail .table-responsive table {
	margin: 0;
}

/* Chat Css Start Here */
.profile_Sec .right-side .peoples {
	background: #f3f3f3;
	padding: 20px 0px;
	overflow: hidden;
}
.profile_Sec .right-side .peoples .search-box {
	position: relative;
	top: 30px;
	transform: translateX(230%);
	transition: 0.6s;
}
.profile_Sec .right-side .peoples .search-box.show {
	transform: translateX(0%);
}
.profile_Sec .right-side .peoples .search-box button.search-btn {
	position: absolute;
	right: 17px;
	top: 8px;
	border: 0;
	background: transparent;
	box-shadow: none;
	outline: none;
	padding: 0;
	font-family: "Poppins";
}
.profile_Sec .right-side .peoples .search-box .form-control {
	border-radius: 0px;
	width: 95%;
	margin: 0px auto;
	font-family: "Poppins";
	font-size: 13px;
	padding: 10px 15px;
}
.profile_Sec .right-side .peoples .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px 20px;
}
.profile_Sec .right-side .peoples .header h3 {
	color: #2b2b2b;
	font-size: 20px;
	font-weight: 600;
}
.profile_Sec .right-side .peoples .chat-box {
	display: flex;
	align-items: flex-start;
	padding: 15px 20px;
	margin-bottom: 3px;
	cursor: pointer;
	position: relative;
}
.profile_Sec .right-side .peoples .chat-box.active {
	background: #fff;
}
.profile_Sec .right-side .peoples .chat-box h4.name {
	font-size: 14px;
	color: #393939;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0 0 2px;
}
.profile_Sec .right-side .peoples .chat-box p {
	font-size: 13px;
	font-family: "Poppins";
	color: #b2b2b2;
	font-weight: 500;
	margin: 0;
}
.profile_Sec .right-side .peoples .chat-box .message {
	padding: 0 0px 0 12px;
}
.profile_Sec .right-side .peoples .chat-box figure img {
	width: 55px;
	height: 39px;
	object-fit: contain;
}
.profile_Sec .right-side .peoples .chat-box figure {
	margin: 0;
}
.profile_Sec .right-side .peoples .all-chats {
	height: 500px;
	overflow-y: auto;
}
.chat-box .total-cart-no {
	background: #72ff13;
	width: 15px;
	height: 15px;
	color: #fff;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	line-height: 1.5;
	position: absolute;
	right: 28px;
	top: 12px;
	font-size: 10px;
}
.profile_Sec .right-side .peoples .all-chats::-webkit-scrollbar {
	width: 0.2em;
}
.profile_Sec .right-side .peoples .all-chats::-webkit-scrollbar-thumb {
	background-color: #ddd;
}

.bg_Images figure img {
	height: 94%;
	padding: 0 10px;
	position: absolute;
	width: 100%;
	object-fit: contain;
}
.profile_Sec .chatting-box {
	height: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 0 10px;
	position: relative;
}
.profile_Sec .chatting-box .header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	padding: 12px 40px 5px;
}

.select-category select {
	background: #535353;
	color: #fff;
	padding: 9px 31px;
	border-radius: 5px;
	font-weight: 500;
	font-family: "Poppins";
	cursor: pointer;
	outline: none;
}
.select-category select option {
	background: #fff;
	color: #535353;
}
.profile_Sec .chatting-box .header .name h4 {
	color: #393939;
	font-size: 14px;
	font-family: "Poppins";
	margin: 0 0 2px;
}
.profile_Sec .chatting-box .header .name p {
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
	margin: 0;
}
.profile_Sec .chatting-box .header figure {
	margin: 0 10px 0 0px;
}
.profile_Sec .chatting-box .send-box {
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #ddd;
	left: 0;
	padding: 10px 10px 10px;
}
.profile_Sec .chatting-box .send-box .form-control {
	border-radius: 0;
	border: 0;
	box-shadow: none;
	outline: none;
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
}
.profile_Sec .chatting-box .send-box .form-control::placeholder {
	color: #b2b2b2;
}
.profile_Sec .chatting-box .send-box button.btn {
	background: #4594f9;
	border: 0;
	color: #ffff;
	font-family: "Poppins";
	padding: 9px 40px;
}
.profile_Sec .chatting-box {
	height: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 0 10px;
	position: relative;
}
.profile_Sec .chatting-box .header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	padding: 12px 40px 5px;
}
.profile_Sec .chatting-box .header .name h4 {
	color: #393939;
	font-size: 14px;
	font-family: "Poppins";
	margin: 0 0 2px;
}
.profile_Sec .chatting-box .header .name p {
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
	margin: 0;
}
.profile_Sec .chatting-box .header figure {
	margin: 0 10px 0 0px;
}
.profile_Sec .chatting-box .send-box {
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #ddd;
	left: 0;
	padding: 10px 10px 10px;
}
.profile_Sec .chatting-box .send-box .form-control {
	border-radius: 0;
	border: 0;
	box-shadow: none;
	outline: none;
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
}
.profile_Sec .chatting-box .send-box .form-control::placeholder {
	color: #b2b2b2;
}
.profile_Sec .chatting-box .send-box button.btn {
	background: #4594f9;
	border: 0;
	color: #ffff;
	font-family: "Poppins";
	padding: 9px 40px;
}
.profile_Sec .chatting-box .messages {
	padding: 20px 10px;
	height: 450px;
	background: #fff;
	overflow-y: scroll;
}
.profile_Sec .chatting-box .messages::-webkit-scrollbar {
	width: 0em;
}
.profile_Sec .chatting-box .messages .messenger {
	display: flex;
	align-items: flex-end;
	max-width: 90%;
	margin-bottom: 40px;
}
.profile_Sec .chatting-box .messages .scnd-side {
	margin: 0px 0px 40px auto;
	width: max-content;
}
.profile_Sec .chatting-box .messages .messenger img.img-fluid {
	max-width: 90px;
}
.profile_Sec .chatting-box .messages .messenger .meesage {
	background: #f8f8f8;
	padding: 10px 10px;
	font-family: "Poppins";
	color: #929292;
	border-radius: 6px 6px 6px 0;
	margin: 0 10px;
}
.profile_Sec .chatting-box .messages .messenger .meesage p {
	font-size: 11px;
	margin: 0;
	/* color: #929292; */
	color: #000;
	/* white-space: nowrap; */
}
.profile_Sec .chatting-box .messages .messenger figure {
	margin: 0;
}
.profile_Sec .chatting-box .messages .messenger .time p {
	margin: 0;
	width: 60px;
	color: #232323;
	font-family: "Poppins";
	font-size: 13px;
}
/* Chat Css End Here */

/* My Adds Css Start Here */
.profile_Sec .right-side .title button {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}
.profile_Sec .right-side .title {
	border: 0;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}

.whatsapp_bg img {
	height: 100vh;
}

.profile_Sec .right-side .head .name-image figure img {
	border: 3px solid #43f22c;
	border-radius: 50%;
	height: 117px;
	min-height: 117px;
	min-width: 117px;
	object-fit: contain;
	width: 117px;
}

.profile_Sec .right-side .title a {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}

.profile_Sec .right-side .title a:hover {
	text-decoration: none;
}
.feature-head .tag {
	display: flex;
	align-items: center;
	background: #72ff13;
	padding: 3px 10px;
	border-radius: 5px;
}
.profile_Sec .tag button.btn.btn-primary {
	padding: 0;
	margin: 0;
	line-height: 0;
	padding: 6px;
}
.profile_Sec .tag .btn-primary.focus,
.btn-primary:focus {
	box-shadow: unset;
}
.profile_Sec .tag .btn-primary.focus,
.btn-primary:focus {
	background-color: unset;
	border: 0;
}
.profile_Sec .tag .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.profile_Sec .tag .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: unset;
	border: 0;
}
.feature-head .tag span {
	font-size: 9px;
	color: #111111;
	font-family: "Montserrat";
	font-weight: 600;
	/* margin-right: 3px; */
}
section.profile_Sec .feature-normal-head h3 {
	line-height: 1;
}
.feature-head .tag img {
	width: 13px;
	filter: brightness(0) invert(1);
}
.profile_Sec .postAdd {
	height: auto;
	border: 0;
	background: transparent;
	padding: 0;
	width: 100%;
}
.profile_Sec .post-add {
	background: #f2f2f2;
	height: 180px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	outline: none;
}
.profile_Sec .post-add h4 {
	color: #444444;
	font-family: "Montserrat";
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 700;
}
.profile_Sec .post-add p {
	font-size: 14px;
	margin: 0;
	color: #929292;
	font-family: "Poppins";
	font-weight: 500;
}
/* My Adds Css End Here */

/* Add Post Modal Css Start Here */
.post-add-modal .modal-open .modal {
	background: #ffffff33;
	backdrop-filter: blur(13px);
	opacity: 1 !important;
}
.post-add-modal .modal-content {
	border-radius: 0;
	padding: 40px 0;
}
.post-add-modal .modal-content .modal-header {
	justify-content: center;
	border: 0;
}
.post-add-modal .modal-content .modal-header img.img-fluid {
	width: 30px;
}
.post-add-modal .modal-content .modal-header span {
	color: #1a1a1a;
	font-family: "Poppins";
	font-weight: 800;
	font-size: 28px;
	padding: 0 10px;
}
.post-add-modal .modal-content .button-group {
	text-align: center;
}
.post-add-modal .modal-content .button-group button.btn {
	background: #72ff13;
	border-radius: 0;
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 600;
	padding: 7px 78px;
	color: #000;
	margin-top: 40px;
}
.post-add-modal .modal-content ul {
	padding: 0;
	list-style: none;
	max-width: 250px;
	margin: 0px auto;
}
.post-add-modal .modal-content ul li {
	text-align: left;
	border-bottom: unset;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.post-add-modal .modal-content ul li ruby {
	color: #444444;
	font-family: "Poppins";
	font-size: 14px;
}
.post-add-modal .modal-content ul li span.price {
	color: #000;
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 600;
	padding-left: 15px;
	text-align: center;
	border-left: unset;
	padding: 8px 0 8px 13px;
}
.post-add-modal .modal-content ul li label {
	margin: 0;
}
.post-add-modal .modal-content ul li > span label {
	position: relative;
	padding-left: 15px;
}
.post-add-modal .modal-content ul li span.dot {
	width: 11px;
	height: 11px;
	position: absolute;
	background: #fff;
	left: -5px;
	top: 7px;
	border-radius: 50%;
	border: 1px solid #fff;
	box-shadow: 0px 0px 0px 1px #72ff13;
}
.post-add-modal .modal-content ul li input:checked ~ span.checkmark span.dot {
	background: #72ff13;
}
/* Add Post Modal Css End Here */

.feature-icon-head span img {
	box-shadow: 0px 0px 13px #0000003b;
	box-shadow: 0px 0px 13px #00000036;
}

.feature-iiimmgg img {
	box-shadow: 0px 0px 13px #0000003b;
	box-shadow: 0px 0px 13px #00000036;
}
/* Bidding Css Start Here */
.profile_Sec table.table th {
	padding: 0 !important;
	border: 0 !important;
}
.profile_Sec table.table td {
	border: 0;
}
.profile_Sec table.table tr {
	border: 0;
}
.profile_Sec table.table th {
	padding: 0 !important;
	color: #333333;
	border: 0 !important;
	font-family: "Poppins";
	font-weight: 600;
	padding-bottom: 6px !important;
	padding: 5px 15px !important;
	width: 25%;
}
.profile_Sec table.table td {
	border: 0;
}
.profile_Sec table.table tr {
	border: 0;
}
.profile_Sec table.table tbody {
	border: 0;
}
.profile_Sec table.table tbody {
	border: 1px solid #ddd;
}
.profile_Sec table.table tbody tr td {
	vertical-align: middle;
	padding: 6px 15px;
	width: 25%;
}
.profile_Sec table.table tbody tr td:first-child {
	color: #5e5e5e;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec table.table tbody tr td:nth-child(2) {
	color: #8a8a8a;
	font-family: "Poppins";
}
.profile_Sec table.table tbody tr td:nth-child(3) {
	font-family: "Poppins";
	color: #8a8a8a;
	font-style: italic;
}
.profile_Sec table.table td button.btn {
	background: #5e5e5e;
	border: 0;
	color: #fff;
	font-size: 13px;
	font-family: "Poppins";
	padding: 10px 35px;
	margin: 0;
}
.profile_Sec table.table tbody tr td {
	vertical-align: middle;
	padding: 6px 15px;
	width: 25%;
	border-bottom: 1px solid #ddd;
}
.profile-detail .bidding-list .button-group {
	padding-top: 60px;
	text-align: end;
}
.profile-detail .bidding-list .button-group button.lnk {
	border: 0;
	background: transparent;
	color: #333333;
	font-family: "Poppins";
	font-weight: 700;
	font-size: 15px;
	box-shadow: none;
	outline: none;
	z-index: 999;
	position: relative;
}
.profile_Sec table.table tbody tr .ev-div {
	display: flex;
	align-items: center;
}
.profile_Sec table.table tbody tr .ev-div figure img {
	max-width: 80px;
}
.profile_Sec table.table tbody tr .ev-div p {
	width: 180px;
	margin: 0;
	color: #8a8a8a;
	font-size: 14px;
	font-style: italic;
	margin-left: 10px;
}
.profile_Sec table.table tbody tr .ev-div figure {
	margin: 0;
}
.profile_Sec table.table tbody tr td {
	font-family: "Poppins";
	color: #8a8a8a;
}
.profile_Sec table.table tbody tr td button.btn {
	background-color: #5e5e5e;
	padding: 11px 35px;
	font-weight: 500;
}
.profile_Sec .sellerbidding table.table tbody tr td button.btn {
	background-color: #72ff13;
	padding: 11px 35px;
	font-weight: 500;
}
/* Bidding Css End Here */

/* edit profile css start here */
.editprofile_sec .button-group a.btn {
	width: 100%;
	color: #000;
	font-family: "Poppins";
	font-weight: 500;
}
/* edit profile css end here */
/* add product css start here */
.addproduct_sec .form-select {
	line-height: 2.8;
}
.addproduct_sec
	.fileUpload
	.form-label
	input.form-control::-webkit-file-upload-button {
	display: none;
}

.addproduct_sec .fileUpload .form-label input.form-control {
	line-height: 2.8;
}
.addproduct_sec .fileUpload label.form-label i.fa.fa-paperclip {
	background-color: #43f22c;
	border: none;
	color: white;
	padding: 15px 22px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	font-size: 27px;
}
.addproduct_sec .button-group a.btn {
	width: 100%;
	color: #000;
	font-family: "Poppins";
	font-weight: 500;
}
.addproduct_sec {
	position: relative;
	background: #f5f5f5;
	padding: 30px 20px;
	height: 100%;
}
.addproduct_sec .form-floating > .form-control:focus ~ label,
.addproduct_sec .form-floating > .form-control:not(:placeholder-shown) ~ label,
.addproduct_sec .form-floating > .form-select ~ label {
	transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
}
/* add product css end here */

/* Profile Css End Here */
