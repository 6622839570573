.thankyou .happyShopping {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 60px 30px;
	width: 40%;
	margin: 90px auto;
	text-align: center;
	background-color: #72ff1329;
}
.setbtnj {
	color: #606060;
	font-weight: 500;
	text-transform: capitalize;
}

@media (max-width: 991px) {
	.thankyou .happyShopping {
		width: 100%;
	}
}
