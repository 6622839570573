.featured-input {
	text-align: center;
	padding: 10px;
	cursor: pointer;
	margin-top: 10px;
}
.featured-sec-head h2 {
	font-size: 33px;
	color: #00151f;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.featured-sec-head {
	text-align: center;
}

.featured-text h5 {
	font-size: 18px;
	color: #242a2d;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.featured-text p {
	font-size: 12px;
	color: #858585;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.featured-text p a {
	color: #249fdb;
	font-weight: 400;
	padding-left: 8px;
	text-decoration: underline;
}

.featured-bold p {
	font-size: 14px;
	color: #242a2d;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	margin: 0;
	position: relative;
}
.featured-bold p span {
	font-weight: 500;
	padding-left: 20px;
}

.featured-box {
	margin-top: 10px;
	position: relative;
}

.featured-input label {
	font-size: 15px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	/* padding-left: 10px; */
	margin: 0;
}

.f-img {
	position: relative;
}

.inner-img {
	position: absolute;
	top: 109px;
	bottom: unset;
	left: 7px;
}

.all-text-things {
	padding: 28px 14px 22px;
	z-index: 999999999;
	position: relative;
}

.inner-img img {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 1px solid #dbdbdb;
	object-fit: cover;
}
.all-text-things .featured-bold {
	border-bottom: 1px solid #ddd;
	padding-bottom: 20px;
}
section.featured-sec {
	background-color: #ffffff;
	/* padding: 50px; */
}

.featured-sec-head h2 {
	font-size: 41px;
	color: #00151f;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	padding-bottom: 30px;
}

.featured-box {
	position: relative;
	border: 1px solid #dbdbdb;
	background-color: #ffffff;
	border-radius: 10px;
}
